import axios from "axios";
import { networkDetails } from "../components/EnclaveSDK/EnclaveUtils/constants";

const API_KEY = process.env.REACT_APP_BIRDEYE_API_KEY;

export const getPriceHistory = async (address, timeType, timeFrom, timeTo, network = 'arbitrum') => {
    const config = {
        method: 'get',
        url: 'https://public-api.birdeye.so/defi/history_price',
        params: {
          address: address,
          address_type: 'token',
          type: timeType,
          time_from: timeFrom,
          time_to: timeTo
        },
        headers: {
          'X-API-KEY': API_KEY,
          'accept': 'application/json',
          'x-chain': network
        }
    };

    const result = await axios(config);
    return result.data;
}

export const getTokenOverview = async (address, network) => {
  const config = {
    method: 'get',
    url: 'https://public-api.birdeye.so/defi/token_overview',
    params: {
      address: address,
    },
    headers: {
      'X-API-KEY': API_KEY,
      'accept': 'application/json',
      'x-chain': network
    }
  };
  const result = await axios(config);
  return result.data;
}

export const getCompleteTokenDetails = async (address, timeType, timeFrom, timeTo, network) => {
  const priceDataPromise = getPriceHistory(address, timeType, timeFrom, timeTo, network);
  const overviewDataPromise = getTokenOverview(address, network);
  const [priceData, overviewData] = await Promise.all([priceDataPromise, overviewDataPromise]);
  return {
    priceData, overviewData
  }
}

export const getNetworkBasedPriceMap = async (balances) => {
  const networkTokensMap = getNetworkBasedTokenMap(balances);
  const priceDataPromises = Object.entries(networkTokensMap).map(async ([chainId, addresses]) => {
    return {
      chainId,
      priceData: await getPriceData(networkDetails[chainId].birdeyeName, addresses)
    }
  });
  const res = {};
  const vals = await Promise.all(priceDataPromises);
  vals.forEach((val) => {
    res[val.chainId] = val.priceData;
  });
  return res;
}

const getNetworkBasedTokenMap = (balances) => {
  let tokens = {}

  balances.forEach((balance) => {
    if (tokens[balance.chainId]) {
      tokens[balance.chainId].push(balance.address);
    } else {
      tokens[balance.chainId] = [balance.address];
    }
  });

  return tokens;
}

export const getPriceData = async (network, addresses) => {
  const priceData = await axios.get(`https://public-api.birdeye.so/defi/multi_price?list_address=${addresses.join(",")}`, {
    headers: {
      'X-API-KEY': API_KEY,
      'accept': 'application/json',
      'x-chain': network
    }
  });

  return priceData.data.data;
}
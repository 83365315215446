// src/serviceWorkerRegistration.js

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      window.location.hostname === '[::1]' ||
      window.location.hostname.match(
        /^127(?:\.(?:[0-9]+)){3}$/
      )
  );
  
  export function register(config) {
    if ('serviceWorker' in navigator) {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      const fcmSwUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js?v=11`;
  
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
        navigator.serviceWorker.ready.then(registration => {
          registration.update();
        });
      }
  
      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
        checkValidServiceWorker(fcmSwUrl, config);
        navigator.serviceWorker.ready.then(() => {
          console.log('Service workers are ready.');
        });
      } else {
        registerValidSW(swUrl, config);
        registerValidSW(fcmSwUrl, config);
      }
    }
  }
  
  function registerValidSW(swUrl, config) {
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        registration.addEventListener('activate', event => {
          // Remove clients.claim() from here
        });
  
        registration.addEventListener('updatefound', () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
  
          installingWorker.addEventListener('statechange', () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                console.log('New service worker available');
                if (config && config.onUpdate) {
                  config.onUpdate(registration);
                }
              } else {
                console.log('Content is cached for offline use');
                if (config && config.onSuccess) {
                  config.onSuccess(registration);
                }
              }
            }
          });
        });
  
        registration.update();
  
        console.log('Service Worker registered successfully:', swUrl);
      })
      .catch((error) => {
        console.error('Service worker registration failed:', error);
      });
  }
  
  function checkValidServiceWorker(swUrl, config) {
    fetch(swUrl)
      .then((response) => {
        if (response.status === 404) {
          console.error('Service worker not found:', swUrl);
        } else {
          registerValidSW(swUrl, config);
        }
      })
      .catch(() => {
        console.log('No internet connection found.');
      });
  }
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister();
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }
  
// Modal.js
import React from "react";
import styles from "./tnxDetailsModal.module.css"; // Create a CSS module for styling
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import { ArrowDown, SendHorizontal } from "lucide-react";
import { getExplorerUrl } from "../EnclaveSDK/EnclaveUtils/functions";
import { formatDate } from "../../utils/functions";
import { networkDetails } from "../../components/EnclaveSDK/EnclaveUtils/constants"
import { getCashToken } from "../../utils/constants";
import { ethers } from "ethers";
import dollarLogo from "../../assets/dollar_jpeg 1.png";
import { formatPrice } from "../../utils/priceUtils";

const replaceUSDC = (str) => str.replace(/USDC/g, "USD");

const TxnDetailsModal = ({
  isOpen,
  closeTxnDetailsModal,
  txn,
  allTokensList,
  chainDetails,
}) => {

  const {walletAddress} = useEnclaveApi();
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    e.stopPropagation();
    if (e.target === e.currentTarget) {
      closeTxnDetailsModal();
    }
  };

  const handleContentClick = (e) => {
    e.stopPropagation(); // Prevent clicks inside modal from closing it
  };

  const spliceAddress = (address) => {
    return (ethers.isAddress(address) || address.length > 12) ?
    address.slice(0, 8) + "..." + address.slice(-4) :
    address
  };

  const assetName = txn.request.label.split(" ")[2];

  const tokenList = [
    getCashToken(networkDetails[chainDetails.id]),
    ...allTokensList
  ];

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContent} onClick={handleContentClick}>
        <div className={styles.tokenLogoBox}>
          <>
            <img
              className={styles.tokenLogo}
              src={
                tokenList.find(
                  (token) => token.symbol === replaceUSDC(txn.request.label.split(" ")[2])
                )?.logoURI
              }
              alt={chainDetails.name}
            />
            {txn.request.label.includes("Swap") ? (
              <img
                className={styles.networkLogo}
                src={
                  txn.request.label.split(" ")[2] == "USD"
                  ? dollarLogo :
                  txn.request.label.includes("Swap")
                    ? tokenList.find(
                        (token) =>
                          token.symbol === replaceUSDC(txn.request.label.split(" ")[5])
                      )?.logoURI
                    : "/extras/transfer.svg"
                }
                style={{
                  width: txn.request.label.includes("Swap") ? "28px" : "24px",
                  height: txn.request.label.includes("Swap") ? "28px" : "24px",
                }}
                alt={chainDetails.name}
              />
            ) : (
              <div className={styles.actionIcon}>
                {txn.request.label.includes("Send") ? (
                  <SendHorizontal size={15} color="white" />
                ) : (
                  <ArrowDown size={18} color="white" />
                )}
              </div>
            )}
          </>
        </div>

        <h1 style={{ marginBottom: "20px" }}>
          {
            replaceUSDC(
            txn.request.label.includes("Swap")
            ? `Swapped ${txn.request.label.split(" ")[2]} for ${
                txn.request.label.split(" ")[5]
              }`
            : txn.request.label.includes("Received") ?
            `${txn.request.label}` :
            `${txn.request.label.slice(0, txn.request.label.indexOf(" "))}
            ${txn.request.label.slice(txn.request.label.indexOf("to"))}`)
          }
        </h1>
        <div className={styles.detailsContainer}>
          <div className={styles.detailRow}>
            <span className={styles.label}>Date</span>
            <span className={styles.value}>
              {formatDate(txn.response.timestamp)}
            </span>
          </div>

          <div className={styles.detailRow}>
            <span className={styles.label}>Network</span>
            <span className={styles.value}>{networkDetails[txn.request.network].name}</span>
          </div>

          <div className={styles.detailRow}>
            <span className={styles.label}>Transaction Hash</span>
            <span className={styles.value}>
              {spliceAddress(txn.response.txnHash)}
            </span>
          </div>
        </div>
        <p className={styles.detailsHeader}>
          {txn.request.label.includes("Swap")
            ? "Swap Details"
            : "Transfer Details"}
        </p>
        <div className={styles.detailsContainer}>

          {
            txn.request.label.includes("Received") ?
            <div className={styles.detailRow}>
              <span className={styles.label}>{replaceUSDC(assetName)} Received</span>
              <span className={styles.value}>
                {"+"}
                {formatPrice(txn.request.label.split(" ")[1])} {replaceUSDC(txn.request.label.split(" ")[2])}
              </span>
            </div> :
            <div className={styles.detailRow}>
            <span className={styles.label}>{replaceUSDC(assetName)} Sent</span>
            <span className={styles.value}>
              {"-"}
              {
              replaceUSDC(
                txn.request.label.slice(
                txn.request.label.indexOf("Swap") + 5,
                txn.request.label.indexOf(" to")
              ))}
            </span>
          </div>

          }
          

          {txn.request.label.includes("Swap") ? (
            <div className={styles.detailRow}>
              <span className={styles.label}>Asset Received</span>
              <span
                className={styles.value}
                style={{ color: "#4dd15b", textAlign: "right" }}
              >
                {"+"}
                {formatPrice(txn.request.label.slice(
                  txn.request.label.indexOf("to") + 3,
                  txn.request.label.lastIndexOf(" ")
                ))}
                {" " +
                  replaceUSDC(txn.request.label.slice(txn.request.label.lastIndexOf(" ")))}
              </span>
            </div>
          ) : (
            txn.request.label.includes("Received") ?
            <></>
            :<div className={styles.detailRow}>
              <span className={styles.label}>Recipient Address</span>
              <span className={styles.value} style={{ textAlign: "right" }}>
                {spliceAddress(
                  txn.request.label.slice(txn.request.label.indexOf("to") + 3)
                )}
              </span>
            </div>
          )}

          <div className={styles.viewButtonContainer}>
            <a
              href={`${getExplorerUrl(txn.request.network)}${
                txn.response.txnHash
              }`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.viewButton}
            >
              View on Explorer
            </a>
          </div>
        </div>
        <button
          className={styles.closeButton}
          onClick={(e) => {
            e.stopPropagation();
            closeTxnDetailsModal();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default TxnDetailsModal;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBzvSndoM0VoS7NHKREVU6S2g9S6THCVm0",
  authDomain: "enclave-wallet.firebaseapp.com",
  projectId: "enclave-wallet",
  storageBucket: "enclave-wallet.appspot.com",
  messagingSenderId: "160004845895",
  appId: "1:160004845895:web:13de9ebf3791aafc29dc62",
  measurementId: "G-BVPFG62H78"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Cloud Messaging
let messaging;
try {
  messaging = getMessaging(app);
} catch (error) {
  console.error("Failed to initialize messaging:", error);
}

export const generateToken = async () => {
  if (!messaging) return null;
  
  try {
    const permission = await Notification.requestPermission();
    console.log("Notification permissions:", permission);

    if (permission === "granted") {
      // Register service worker first
      if ('serviceWorker' in navigator) {
        try {
          // Check for existing registration with same scope
          const existingReg = await navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope');
          
          let registration;
          if (!existingReg) {
            registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js?v=11', {
              scope: '/firebase-cloud-messaging-push-scope'
            });
            console.log('Service Worker registered with scope:', registration.scope);
          } else {
            registration = existingReg;
            console.log('Using existing Service Worker registration');
          }

          registration.update();
          
          // Now get the token
          const currentToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID_KEY,
            serviceWorkerRegistration: registration
          });
          
          console.log("FCM token:", currentToken);
          return currentToken;
        } catch (err) {
          console.error('Service Worker registration failed:', err);
          throw err;
        }
      }
    }
  } catch (error) {
    console.error("Failed to get FCM token:", error);
    throw error;
  }
};

export { messaging, analytics }
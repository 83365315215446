import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./common.module.css";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import { enabledNetworks } from "../EnclaveSDK/EnclaveUtils/constants";
import isPWA from "../../utils/pwaUtils";
import Refresh from "@mui/icons-material/Refresh";
import { formatPrice } from "../../utils/priceUtils";
import { Flame, SearchIcon } from "lucide-react";
import ExploreOptionsDropdown from "../ExploreOptionsDropdown";
import WarningIcon from "@mui/icons-material/Warning";
import CampaignIcon from "@mui/icons-material/Campaign";
import { RocketLaunch } from "@mui/icons-material";
import { getRep } from "../../utils/functions";
import NameBox from "../EnclaveSDK/NameBox";
import { setWindowEnclave } from "../EnclaveSDK/EnclaveUtils/enclaveWindowFunctions";
import { motion, AnimatePresence } from "framer-motion";
import TokenDetailsDrawer from "../tokensDetailsDrawer/tokensDetailsDrawer";
import { TokenDetailsBottomNav } from "../tokenDetailsBottomNav";

const viewOptions = {
  vol: "Trending",
  gain: "Gainers",
  lose: "Losers",
  mc: 'Market Cap'
};

const notBasicToken = (tokenDetails) => {
  return !!tokenDetails.logoURI && ![""].includes(tokenDetails.symbol);
};

export default function ExplorePage() {
  const {
    tokenListLoading,
    allTokensList,
    userData,

    setUserData,
  } = useEnclaveApi();
  const navigate = useNavigate();
  const tokenRowsRef = useRef(Array(1000).fill(null));
  const [searchTerm, setSearchTerm] = useState();
  const [connectionState, setConnectionState] = useState("disconnected");
  const [nameBoxOpen, setNameBoxOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [selectedToken, setSelectedToken] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [view, setView] = useState(() => {
    const savedView = sessionStorage.getItem("exploreView");
    return savedView ? savedView : viewOptions.vol;
  });

  console.log("View: ", view);

  const chipContainerRef = useRef(null);

  const handleMouseDown = (e) => {
    const container = chipContainerRef.current;
    container.style.cursor = "grabbing";
    container.style.userSelect = "none";

    const startX = e.pageX - container.offsetLeft;
    const scrollLeft = container.scrollLeft;

    const onMouseMove = (e) => {
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 2; // Adjust scroll speed
      container.scrollLeft = scrollLeft - walk;
    };

    const onMouseUp = () => {
      container.style.cursor = "grab";
      container.style.removeProperty("user-select");
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  useEffect(() => {
    if (Object.keys(userData ?? {}).length > 0) {
      setWindowEnclave(userData);
      setConnectionState("connected");
    } else {
      setConnectionState("disconnected");
    }
  }, [userData]);

  useEffect(() => {
    const savedScrollState = sessionStorage.getItem("exploreScrollState");
    if (savedScrollState) {
      const { view: savedView } = JSON.parse(savedScrollState);
      setView(savedView);

      // Remove the saved state after restoring
      sessionStorage.removeItem("exploreScrollState");
    }

    const savedTokenIndex = sessionStorage.getItem("exploreTokenIndex");
    if (savedTokenIndex) {
      setTimeout(() => {
        const element = tokenRowsRef.current[parseInt(savedTokenIndex, 10)];
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        sessionStorage.removeItem("exploreTokenIndex");
      }, 100);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("exploreView", view);
  }, [view]);

  const handleRefreshClick = () => {
    window.location.reload(); // Reloads the current page
  };

  const getSortedTokenList = () => {
    return allTokensList
      .filter((token) =>
        enabledNetworks.some((network) =>
          token.chainIds.some((obj) => obj.chainId === network)
        )
      )
      .filter((token) => !token.symbol.includes("USD") && !token.symbol.includes("EUR"))
      .filter((token) => !!token.mc && !!token.v24hUSD && token.v24hUSD > 0)
      .filter((token) => {
        if (!searchTerm) return true;
        const lowercaseSearchTerm = searchTerm.toLowerCase();
        return (
          token.symbol.toLowerCase().includes(lowercaseSearchTerm) ||
          token.name.toLowerCase().includes(lowercaseSearchTerm) ||
          Object.values(token.chainIds).some((chainDetails) =>
            chainDetails.address.toLowerCase().includes(lowercaseSearchTerm)
          )
        );
      })
      .sort((a, b) => {
        switch (view) {
          case viewOptions.vol: {
            if (a.liquidity == 0) return 1;
            if (b.liquidity == 0) return 0;
            return b.v24hUSD / b.liquidity - a.v24hUSD / a.liquidity;
          }
          case viewOptions.gain:
            return (
              parseFloat(b.priceChange24h ?? 0) -
              parseFloat(a.priceChange24h ?? 0)
            );
          case viewOptions.lose:
            return (
              parseFloat(a.priceChange24h ?? 0) -
              parseFloat(b.priceChange24h ?? 0)
            );
          case viewOptions.mc:
            return parseFloat(b.mc ?? 0) - parseFloat(a.mc ?? 0);
          default:
            return b.v24hUSD - a.v24hUSD;
        }
      });
  };

  const handleTokenClick = (symbol, index) => {
    if (connectionState === "disconnected") {
      setNameBoxOpen(true);
      return;
    }
    sessionStorage.setItem("exploreTokenIndex", index.toString());
    sessionStorage.setItem(
      "exploreScrollState",
      JSON.stringify({
        searchTerm: searchTerm,
        view: view,
      })
    );

    if (!isPWA()) {
      navigate(`/explore/token/${symbol}`);
      return;
    }

    const token = allTokensList.find((t) => t.symbol === symbol);
    setSelectedToken(token);
    setIsDrawerOpen(true);
  };

  const spotLightContent = () => {
    if (tokenListLoading) {
      return (
        <div className={styles.spotlightCard}>
          <div className={styles.skeletonSpotlight}>
            <div className={styles.skeletonSpotlightContent}>
              <div className={styles.skeletonLogo} />
              <div className={styles.skeletonText}>
                <div className={styles.skeletonLine} />
                <div className={styles.skeletonLine} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (!allTokensList || allTokensList.length === 0) return null;

    // Find the token with the highest price change
    const topGainer = allTokensList.reduce((max, token) => {
      const change = parseFloat(token.priceChange24h ?? 0);
      return change > parseFloat(max.priceChange24h ?? 0) ? token : max;
    }, allTokensList[0]);

    const delta = parseFloat(topGainer.priceChange24h);
    const gain = delta > 0;
    const loss = delta < 0;

    const deltaView = isNaN(delta) ? "-" : Math.abs(delta).toFixed(2);

    return (
      <div onClick={() => handleTokenClick(topGainer.symbol, 0)}>
        <div className={styles.cardSection}>
          <div className={styles.spotlightCardTextContainer}>
            <div className={styles.logoWrapper}>
              <img
                src={topGainer.logoURI}
                alt={topGainer.symbol}
                className={styles.tokenLogo}
              />
            </div>

            <div className={styles.spotlightCardText}>
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                {topGainer.name}
              </span>
              <span style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
                {topGainer.symbol}
              </span>
            </div>
          </div>
          <div>
            <span className={styles.tokenPriceSpotlight}>
              ${formatPrice(topGainer.price)}
            </span>
            <span
              style={{ fontSize: "1rem" }}
              className={`${styles.delta} ${
                gain
                  ? styles.spotLightGain
                  : loss
                  ? styles.loss
                  : styles.neutral
              }`}
            >
              {gain ? <>&#x25B2;</> : <>&#x25BC;</>}
              {deltaView}%
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${styles.page} ${isPWA() ? styles.pageOverrides : ""}`}
      style={{
        maxWidth: "960px",
        margin: "auto",
        padding: "5px 14px",
        maxHeight: "100vh",
      }}
    >
      {nameBoxOpen && (
        <NameBox
          setNameBox={setNameBoxOpen}
          setUserName={setUserName}
          setConnectionState={setConnectionState}
          setUserData={setUserData}
        />
      )}

      <div className={styles.exploreOptionsContainer}>
        <div className={styles.exploreSearchContainer}>
          <SearchIcon />
          <input
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.currentTarget.value);
            }}
          />
        </div>
      </div>

      {searchTerm ? (
        <div className={styles.balancesTableWrapper}>
          <table className={styles.balancesTable}>
            <tbody>
              {getSortedTokenList().map((tokenDetails, index) => (
                <TokenRow
                  key={`${view}-${tokenDetails.symbol}-${index}`}
                  tokenDetails={tokenDetails}
                  index={index + 1}
                  view={view}
                  onTokenClick={handleTokenClick}
                  rowRef={tokenRowsRef}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : tokenListLoading ? (
        <div>
          {/* Spotlight Section Skeleton */}
          <div className={styles.spotlightHeadingDiv}>
            <CampaignIcon fontSize="large" />
            <h1 style={{ marginBottom: "0px" }}>Spotlight</h1>
          </div>
          {spotLightContent()}

          {/* Top Gainers Section Skeleton */}
          <div className={styles.topGainersHeadingDiv}>
            <RocketLaunch fontSize="large" />
            <h1 style={{ marginBottom: "0px" }}>Top Gainers</h1>
          </div>
          <div className={styles.chipContainer}>
            {[1, 2, 3, 4, 5].map((_, index) => (
              <div key={index} className={styles.skeletonChip} />
            ))}
          </div>

          {/* Tokens Section Skeleton */}
          <div className={styles.exploreOptionsContainer}>
            <div className={styles.trendingHeadingDiv}>
              <Flame size={30} />
              <h1 style={{ marginBottom: "0px" }}>Tokens</h1>
            </div>
            <div>
              <ExploreOptionsDropdown
                selectedOption={view}
                setSelectedOption={setView}
                optionList={Object.values(viewOptions)}
              />
            </div>
          </div>
          <br />
          <div className={styles.balancesTableWrapper}>
            <table className={styles.balancesTable}>
              <tbody>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
                  <tr key={index} className={styles.skeletonRow}>
                    <td className={styles.indexCell}>{index + 1}</td>
                    <td className={styles.logoCell}>
                      <div className={styles.skeletonLogo} />
                    </td>
                    <td className={styles.priceInfo}>
                      <div
                        className={styles.skeletonLine}
                        style={{ width: "200px" }}
                      />
                      <div
                        className={styles.skeletonLine}
                        style={{ width: "60px" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            overflowY: "auto",
            scrollbarWidth: "none",
            paddingBottom: "180px",
            WebkitScrollbar: "none",
            "-webkit-scrollbar": "none",
          }}
          className={styles.noScrollbar}
        >
          <div className={styles.spotlightHeadingDiv}>
            <CampaignIcon fontSize="large" />
            <h1 style={{ marginBottom: "0px" }}>Spotlight</h1>
          </div>

          <div className={styles.spotlightCard}>
            {allTokensList.length > 0 && spotLightContent()}
          </div>

          <div className={styles.topGainersHeadingDiv}>
            <RocketLaunch fontSize="large" />
            <h1 style={{ marginBottom: "0px" }}>Top Gainers</h1>
          </div>

          <div
            className={styles.chipContainer}
            ref={chipContainerRef}
            onMouseDown={handleMouseDown}
          >
            {getSortedTokenList()
              .filter((token) => parseFloat(token.priceChange24h) > 0) // Filter for top gainers
              .sort(
                (a, b) =>
                  parseFloat(b.priceChange24h) - parseFloat(a.priceChange24h)
              ) // Sort by gains descending
              .map((tokenDetails, index) => (
                <div
                  key={index}
                  className={styles.chip}
                  onClick={() => handleTokenClick(tokenDetails.symbol, index)}
                >
                  <img
                    src={tokenDetails.logoURI}
                    alt={tokenDetails.symbol}
                    className={styles.chipLogo}
                  />
                  <span style={{ fontSize: "1.1rem" }}>
                    {tokenDetails.symbol}
                  </span>
                  <span className={styles.chipGain}>
                    &#x25B2;{" "}
                    {parseFloat(tokenDetails.priceChange24h).toFixed(2)}%
                  </span>
                </div>
              ))}
          </div>

          <div className={styles.exploreOptionsContainer}>
            <div className={styles.trendingHeadingDiv}>
              <Flame size={30} />
              <h1 style={{ marginBottom: "0px" }}>Tokens</h1>
            </div>
            <div>
              <ExploreOptionsDropdown
                selectedOption={view}
                setSelectedOption={setView}
                optionList={Object.values(viewOptions)}
              />
            </div>
          </div>
          <br></br>
          <div>
            {/* <div className={styles.pillContainer}>
							<button 
								className={`${styles.pill} ${view === viewOptions.vol ? styles.activePill : ''}`}
								onClick={() => setView(viewOptions.vol)}
							>
								Majors
							</button>
							<button 
								className={`${styles.pill} ${view === viewOptions.gain ? styles.activePill : ''}`}
								onClick={() => setView(viewOptions.gain)}
							>
								Gainers
							</button>
							<button 
								className={`${styles.pill} ${view === viewOptions.lose ? styles.activePill : ''}`}
								onClick={() => setView(viewOptions.lose)}
							>
								Losers
							</button>
						</div> */}

            {allTokensList && allTokensList.filter(notBasicToken).length > 0 ? (
              <div className={styles.balancesTableWrapper}>
                <table className={styles.balancesTable}>
                  <tbody>
                    {getSortedTokenList()
                      // .sort((a, b) => b.v24hUSD - a.v24hUSD) // Sort by 24-hour trading volume
                      .map((tokenDetails, index) => (
                        <TokenRow
                          key={`${view}-${tokenDetails.symbol}-${index}`}
                          tokenDetails={tokenDetails}
                          index={index + 1}
                          view={view}
                          onTokenClick={handleTokenClick}
                          rowRef={tokenRowsRef}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={styles.noBalances}>
                <Refresh size={48} />
                <p>Unable to load data. Please refresh.</p>
                <br />
                <button onClick={handleRefreshClick} className={"btn-primary"}>
                  Refresh
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <AnimatePresence>
        {isDrawerOpen && selectedToken && (
          <TokenDrawer
            token={selectedToken}
            onClose={() => {
              setIsDrawerOpen(false);
              setSelectedToken(null);
            }}
            setSelectedToken={setSelectedToken}
            getSortedTokenList={getSortedTokenList}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

const TokenRow = ({ tokenDetails, index, view, onTokenClick, rowRef }) => {
  const delta = parseFloat(tokenDetails.priceChange24h);
  const gain = delta > 0;
  const loss = delta < 0;

  const deltaView = isNaN(delta) ? "-" : Math.abs(delta).toFixed(2);

  const risky =
    tokenDetails.status?.allowance && !tokenDetails.status?.transferFrom;

  return (
    <tr
      ref={(el) => (rowRef.current[index] = el)}
      className={styles.yieldRow}
      onClick={() => onTokenClick(tokenDetails.symbol, index)}
    >
      <td className={styles.indexCell}>{index}</td>
      <td className={styles.logoCell}>
        <div className={styles.logoWrapper}>
          <img
            src={tokenDetails.logoURI}
            width={48}
            alt={tokenDetails.symbol}
          />
        </div>
      </td>
      <td className={styles.tokenInfo}>
        <h3>{tokenDetails.symbol}</h3>
        <span style={{ color: "#888" }}>
          ${getRep(tokenDetails.mc, 1)} MKT CAP
        </span>
        <span
          className={styles.loss}
          style={{
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {risky ? (
            <>
              <WarningIcon sx={{ fontSize: "12px" }} /> Warning
            </>
          ) : (
            <></>
          )}
        </span>
      </td>
      <td className={styles.priceInfo}>
        <span className={styles.tokenPrice}>
          ${formatPrice(tokenDetails.price)}
        </span>
        <span
          className={`${styles.delta} ${
            gain ? styles.gain : loss ? styles.loss : styles.neutral
          }`}
        >
          {gain ? <>&#x25B2;</> : <>&#x25BC;</>}
          {deltaView}%
        </span>
      </td>
    </tr>
  );
};

const TokenDrawer = ({ token, onClose, setSelectedToken, getSortedTokenList }) => {
  const drawerRef = useRef(null);
  const contentRef = useRef(null);
  
  const sortedTokens = getSortedTokenList();
  const currentIndex = sortedTokens.findIndex(t => t.symbol === token.symbol);

  const handleNavigation = (direction) => {
    const newIndex = direction === 'next' 
      ? (currentIndex + 1) % sortedTokens.length
      : (currentIndex - 1 + sortedTokens.length) % sortedTokens.length;
    
    setSelectedToken(sortedTokens[newIndex]);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.5 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        className={styles.backdrop}
        onClick={onClose}
      />
      <motion.div
        ref={drawerRef}
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        exit={{ y: "100%" }}
        transition={{ duration: 0.3 }}
        className={styles.tokenDrawer}
        style={{ height: "100vh" }}
      >
        <TokenDetailsBottomNav 
          closeDrawer={onClose}
          onNext={() => handleNavigation('next')}
          onPrevious={() => handleNavigation('previous')}
          hasNext={currentIndex < sortedTokens.length - 1}
          hasPrevious={currentIndex > 0}
        />
        <div 
          ref={contentRef} 
          className={styles.drawerContent}
          style={{ height: "calc(100vh - 60px)" }}
        >
          <TokenDetailsDrawer token={token} />
        </div>
      </motion.div>
    </>
  );
};

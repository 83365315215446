import { useEffect, useState, useRef } from "react";
import styles from "./css/nameBox.module.css";
import {
  checkInviteCode,
  checkUserName,
  sendError,
  signInAccount,
} from "./EnclaveUtils/functions";
import { createAccount } from "./EnclaveUtils/functionsV3";
import { setWindowEnclave } from "./EnclaveUtils/enclaveWindowFunctions";
import { CircularProgress } from "@mui/material";
import { useEnclaveConnect } from "./context/EnclaveConnectProvider";
import isPWA from "../../utils/pwaUtils";
import { LogIn } from "lucide-react";
import Logo from "./assets/logos/EndeavourTransparet.png";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default function NameBox(props) {
  const { setUserName, setWalletAddress, setUserData } = useEnclaveConnect();
  const bg = useRef(null);

  const [warningText, setWarningText] = useState("");
  const [inviteWarningText, setInviteWarningText] = useState("");
  const [btnText, setBtnText] = useState("Create");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const [appState, setAppState] = useState("noName");

  const [userExixts, setUserExists] = useState(false);

  const [inviteCode, setInviteCode] = useState("");

  const [isInviteCodeValid, setIsInviteCodeValid] = useState(false);

  const debouncedName = useDebounce(name, 500);
  const debouncedInviteCode = useDebounce(inviteCode, 500);
  const [step, setStep] = useState(0);

  useEffect(() => {
    bg.current.addEventListener("click", (e) => {
      if (e.target === bg.current) {
        props.setNameBox(false);
        props.setConnectionState("disconnected");
      }
    });
  }, []);

  const btnClicked = async (e) => {
    e.preventDefault();

    if (appState === "nameEntered") {
      if (userExixts) {
        setLoading(true);
        try {
          const res = await signInAccount(name);
          console.log(res);
          if (res.authenticated) {
            localStorage.setItem("userData", JSON.stringify(res));
            console.log(localStorage.getItem("userData"));
            setUserName(res.username);
            setWalletAddress(res.wallet.scw_address);
            setUserData(res);
            props.setConnectionState("connected");
            props.setNameBox(false);
            setWindowEnclave(res);
          } else {
            setWarningText("Sign in failed");
          }
        } catch (error) {
          console.error("E1: ", error);
          sendError({
            message: "SIGN IN FAIL",
            error: JSON.stringify(error),
          });
        } finally {
          setLoading(false);
        }
      } else {
        if (step === 0) {
          setStep(1);
          return;
        } else {
          try {
            const res = await createAccount(name, inviteCode);
            console.log(res);
            if (res.wallet.scw_address) {
              localStorage.setItem("userData", JSON.stringify(res));
              setUserName(res.username);
              setWalletAddress(res.wallet.scw_address);
              setUserData(res);
              props.setConnectionState("connected");
              props.setNameBox(false);
              setWindowEnclave(res);
            } else {
              setWarningText("Account Creation failed");
            }
          } catch (error) {
            console.error("E2: ", error);
            sendError({
              message: "CREATE ACCOUNT FAIL",
              error: JSON.stringify(error),
            });
          } finally {
            setLoading(false);
          }
        }
      }
    }

    if (appState === "accountCreated" || appState === "loggedIn") {
      setWarningText("Signing in...");
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setAppState("loggedIn");
        props.setNameBox(false);
      }, 1000);
    }
  };

  useEffect(() => {
    console.log("Name entered: ", debouncedName);
    if (debouncedName.length > 0) {
      setAppState("nameEntered");
      setLoading(true);
      setWarningText("Searching for user...");
      (async () => {
        try {
          const res = await checkUserName(debouncedName);
          console.log(res.value);
          if (res.value === true) {
            setWarningText("User already exists");
            setBtnText("Sign In");
            setUserExists(true);
          } else {
            setWarningText("New User Detected");
            setBtnText("Continue");
            setUserExists(false);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setAppState("noName");
      setWarningText("");
      setUserExists(false);
    }
  }, [debouncedName]);

  useEffect(() => {
    console.log("Invite Code: ", debouncedInviteCode);
    if (debouncedInviteCode.length > 0) {
      (async () => {
        try {
          const res = await checkInviteCode(debouncedInviteCode);
          if (res?.valid === true) {
            setInviteWarningText("");
            setIsInviteCodeValid(true);
          } else {
            setInviteWarningText("Invalid Invite Code");
            setIsInviteCodeValid(false);
          }
        } catch (error) {
          console.error("E3: ", error);
          setInviteWarningText("Invalid Invite Code");
          setIsInviteCodeValid(false);
        }
      })();
    } else {
      setIsInviteCodeValid(false);
    }
  }, [debouncedInviteCode]);

  const isButtonDisabled = () => {
    if (name.length <= 0) return true;
    if (step === 1 && (inviteCode.length <= 0 || !isInviteCodeValid))
      return true;
    return false;
  };

  return (
    <div ref={bg} className={styles.main}>
      <div
        className={`${styles.popUp} ${isPWA() ? styles.popUpOverrides : ""}`}
      >
        <div className={styles.logoContainer}>
          <img style={{ width: "35px" }} src={Logo} alt="Connect" />
          {/* <LogIn /> */}
        </div>
        <h1>Sign In</h1>
        {step === 0 && (
          <>
            <input
              onChange={(e) => {
                // Check if input contains special characters
                const hasSpecialChars = /[^a-zA-Z0-9_]/.test(e.target.value);
                if (hasSpecialChars) {
                  setWarningText("Username should not include special characters");
                }
                // Only allow alphanumeric characters and underscores
                const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9_]/g, '');
                setName(sanitizedValue.toLowerCase());
              }}
              value={name}
              type="text"
              placeholder="Enter your username here...."
            />
            {warningText.length > 0 && <p>{warningText}</p>}
          </>
        )}

        {warningText === "New User Detected" && step === 1 && (
          <>
            <input
              type="text"
              placeholder="Enter Invite Code here...."
              value={inviteCode}
              onChange={(e) => setInviteCode(e.target.value)}
            />
            {inviteWarningText.length > 0 && <p>{inviteWarningText}</p>}
          </>
        )}
        <button
          disabled={isButtonDisabled()}
          onMouseDown={btnClicked}
          className={`${
            isButtonDisabled() && styles.disabled
          } ${"btn-primary"}`}
        >
          {loading ? (
            <CircularProgress
              size={20}
              color="inherit"
              style={{ color: "#111" }}
            />
          ) : (
            <span style={{ fontSize: "1.2rem" }}>{btnText.length > 0 && btnText}</span>
          )}
        </button>
        <div className={styles.poweredByContainer}>
          <span className={styles.poweredBy}>Powered by </span>
          <img
            style={{
              width: "15px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            src="/logos/enclave.svg"
            alt="Enclave"
          />{" "}
          <span className={styles.enclave}>Enclave</span>
        </div>
      </div>
    </div>
  );
}

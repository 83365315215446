import React, { useState, useEffect, useMemo } from "react";
import Numpad from "../Numpad";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";

import { debounce } from "lodash";
import {
  getERC20TransferCallData,
  getUsernameForWalletAddress,
  getWalletAddressForUsername,
} from "../EnclaveSDK/EnclaveUtils/functions";
import { convertToNetworkTokenList } from "../../utils/tokenListUtils";
import { processBalances2 } from "../../utils/functions";
import styles from "./common.module.css";
import SwapTokenSelect from "../SwapTokenSelect";
import {
  networkDetailsByNameKey,
  tokens,
  getCashToken,
  TNX_TYPE,
} from "../../utils/constants";
import { ArrowBack } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { BadgeCheck } from "lucide-react";
import {
  enabledNetworks,
  networkDetails,
} from "../EnclaveSDK/EnclaveUtils/constants";
import { computeQuote } from "../EnclaveSDK/EnclaveUtils/functionsV3";
import { JsonRpcProvider, ethers } from "ethers";

const networkList = Object.values(networkDetailsByNameKey);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const resolveENS = async (ensName) => {
  try {
    const provider = new JsonRpcProvider(
      "https://mainnet.infura.io/v3/" + process.env.REACT_APP_INFURA_KEY
    );
    const address = await provider.resolveName(ensName);
    console.log("ENS ADDRESS: $$$$$$$$$$$$$$ ", address);
    return address;
  } catch (error) {
    console.error("ENS resolution error:", error);
    return null;
  }
};

const reverseResolveENS = async (address) => {
  try {
    const provider = new JsonRpcProvider(
      "https://mainnet.infura.io/v3/" + process.env.REACT_APP_INFURA_KEY
    );
    const ensName = await provider.lookupAddress(address);
    return ensName;
  } catch (error) {
    console.error("ENS reverse resolution error:", error);
    return null;
  }
};

function TransferPage() {
  const query = useQuery();

  const [cashTransferMode, setCashTransferMode] = useState(true);

  const { balances, allTokensList, walletAddress, smartBalanceObject } =
    useEnclaveApi();

  const pdestinationNetwork = query.get("pdestinationNetwork");
  const pdestinationAddress = query.get("pdestinationAddress");
  const wallet = query.get("wallet");

  const [sourceNetwork, setSourceNetwork] = useState(
    pdestinationNetwork
      ? networkDetails[parseInt(pdestinationNetwork)]
      : networkDetails[enabledNetworks[1]]
  );

  const destinationTokenList = useMemo(
    () => convertToNetworkTokenList(sourceNetwork.id, allTokensList),
    [sourceNetwork.id, allTokensList]
  );
  destinationTokenList.push(
    getCashToken({
      id: pdestinationNetwork,
      name: networkDetails[sourceNetwork.id].name,
    })
  );

  const [fromToken, setFromToken] = useState(null);

  useEffect(() => {
    if (!fromToken && destinationTokenList?.length > 0) {
      if (pdestinationAddress) {
        const foundToken = destinationTokenList.find(
          (token) =>
            token.address.toLowerCase() === pdestinationAddress.toLowerCase()
        );
        if (foundToken) {
          setFromToken(foundToken);
        } else {
          setFromToken(destinationTokenList[0]);
        }
      } else {
        setFromToken(destinationTokenList[0]);
      }
    }
    if (wallet) {
      setAddress(wallet);
      handleAddressChange(wallet);
    }
  }, [destinationTokenList, pdestinationAddress]);

  const [sourceNetworkBalances, setSourceNetworkBalances] = useState({});
  const [sourceAmount, setSourceAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [paymasterInsolvent, setPaymasterInsolvent] = useState(false);
  const [usernameCheckLoading, setUsernamecheckLoading] = useState(false);

  console.log("ERROR: ", error);

  const [address, setAddress] = useState("");
  const [sendToUser, setSendToUser] = useState({
    status: false,
    username: undefined,
    walletAddress: undefined,
  });
  const [addressToSend, setAddressToSend] = useState("");
  const [usdcQuote, setUSDCQuote] = useState({});

  const txnChainId = sourceNetwork.id;
  const balanceByChainId =
    parseInt(
      smartBalanceObject.balanceByNetwork.find(
        (balanceObj) => balanceObj.network === txnChainId
      )?.value ?? 0
    ) / 1000000;
  const deficit =
    fromToken?.address == "0xEnclave"
      ? parseFloat(sourceAmount) - balanceByChainId < 0
        ? 0
        : parseFloat(sourceAmount) - balanceByChainId
      : undefined;
  const orderData =
    fromToken?.address == "0xEnclave"
      ? {
          amount: Math.floor(sourceAmount * 1000000),
          type: "AMOUNT_OUT",
        }
      : undefined;

  const validAmount = parseFloat(sourceAmount) > 0;
  const withinBalance =
    fromToken?.address == "0xEnclave"
      ? parseFloat(sourceAmount) <=
        parseFloat(smartBalanceObject.netBalance) / 1000000
      : ethers.parseUnits(sourceAmount || "0", fromToken?.decimals) <=
        ethers.parseUnits(
          sourceNetworkBalances[
            fromToken?.chainIds
              ?.find((chain) => chain.chainId === sourceNetwork.id)
              ?.address?.toLowerCase()
          ]?.total || "0",
          fromToken?.decimals
        );
  const validToAddress = ethers.isAddress(addressToSend);
  const validSend = validAmount && withinBalance && validToAddress && !error;

  // Create memoized debounced function
  const debouncedUsernameCheck = useMemo(
    () =>
      debounce(async (value) => {
        if (value && value.length > 0) {
          setUsernamecheckLoading(true);

          const isEthereumAddress = (value) => {
            return /^0x[a-fA-F0-9]{40}$/.test(value);
          };

          const isENS = (value) => {
            return value.toLowerCase().endsWith(".eth");
          };

          let res;

          if (isENS(value)) {
            const ensAddress = await resolveENS(value);
            if (ensAddress) {
              res = await getUsernameForWalletAddress(ensAddress);
              if (!res.value) {
                // If no username found, still use the ENS resolution
                res = {
                  value: true,
                  username: value,
                  walletAddress: ensAddress,
                };
              }
            }
          } else if (isEthereumAddress(value)) {
            // Check for reverse ENS resolution first
            const ensName = await reverseResolveENS(value);
            res = await getUsernameForWalletAddress(value);
            if (res?.value && ensName) {
              // If both username and ENS exist, include both
              res.ensName = ensName;
            } else if (ensName) {
              // If only ENS exists
              res = {
                value: true,
                username: ensName,
                walletAddress: value,
                ensName: ensName,
              };
            }
          } else {
            res = await getWalletAddressForUsername(value);
          }

          if (res?.value) {
            setSendToUser({
              status: true,
              username: res.username,
              walletAddress: res.walletAddress,
              ensName: res.ensName, // This will be undefined if no ENS name exists
            });
            setAddressToSend(res.walletAddress);
          } else {
            setSendToUser({
              status: false,
              username: undefined,
              walletAddress: undefined,
              ensName: undefined,
            });
            setAddressToSend(value);
          }
          setUsernamecheckLoading(false);
        }
      }, 500),
    []
  );

  const handleAddressChange = (value) => {
    setAddress(value);
    setLoading(true);
    setError(false);
    debouncedUsernameCheck(value);
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      debouncedUsernameCheck.cancel();
    };
  }, [debouncedUsernameCheck]);

  console.log({ sendToUser });
  useEffect(() => {
    const balanceMap = processBalances2(
      balances.filter((balance) => balance.chainId === sourceNetwork.id)
    );
    setSourceNetworkBalances(balanceMap);
  }, [balances, sourceNetwork]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          fromToken.address == "0xEnclave" &&
          sourceAmount.length > 0 &&
          parseFloat(sourceAmount) > 0 &&
          fromToken &&
          addressToSend
        ) {
          let usdcQuote1;
          try {
            usdcQuote1 = await computeQuote(
              walletAddress,
              sourceNetwork.id,
              Math.floor(parseFloat(sourceAmount) * 1000000),
              "AMOUNT_OUT"
            );
            console.log("USDC QUOTE: ", usdcQuote);
            if (!usdcQuote1.withdrawals) {
              setError(true);
              setPaymasterInsolvent(true);
            } else {
              setUSDCQuote(usdcQuote1);
            }
          } catch (e) {
            setError(true);
            console.log("Error", e);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchDataDebounce = debounce(fetchData, 500);
    setLoading(true);
    setError(false);
    fetchDataDebounce();

    return () => fetchDataDebounce.cancel();
  }, [
    sourceAmount,
    fromToken?.address,
    addressToSend,
    fromToken?.decimals,
    fromToken?.chainId,
  ]);

  const handleSubmit = (addressToSend) => {
    if (fromToken.address == "0xEnclave") {
    }
    if (fromToken.address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
      const value = ethers.parseEther(sourceAmount);
      window.enclave.initiateTransaction(
        [
          {
            label: "Send " + fromToken.symbol,
            calldata: "0x",
            value: value,
            targetContractAddress: addressToSend,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
          },
        ],
        `Send ${sourceAmount} ${fromToken.symbol} to ${
          sendToUser.username ??
          addressToSend.substring(0, 6) +
            "..." +
            addressToSend.substring(addressToSend.length - 4)
        }`
      );
    } else {
      window.enclave.initiateTransaction(
        [
          {
            label: "Send " + fromToken.symbol,
            calldata: getERC20TransferCallData(
              sourceAmount,
              fromToken.address == "0xEnclave"
                ? tokens["USDC"][sourceNetwork.id]
                : fromToken.address,
              addressToSend,
              fromToken.address == "0xEnclave" ? 6 : fromToken.decimals
            ),
            targetContractAddress:
              fromToken.address == "0xEnclave"
                ? tokens["USDC"][sourceNetwork.id]
                : fromToken.address,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
          },
        ],
        `Send ${sourceAmount} ${fromToken.symbol} to ${
          sendToUser?.username ??
          addressToSend.substring(0, 6) +
            "..." +
            addressToSend.substring(addressToSend.length - 4)
        }`,
        undefined,
        undefined,
        orderData,
        fromToken.address == "0xEnclave"
          ? deficit <= 0
            ? "GASLESS"
            : "ABSTRACTED_INTERNAL"
          : "GASLESS",
        {
          type: TNX_TYPE.TRANSFER,
          amount: sourceAmount + " " + fromToken.symbol,
          recipient: sendToUser?.username ?? addressToSend,
        }
      );
    }
  };

  const handleNumberClick = (number) => {
    // Handle initial zero
    if (sourceAmount === "0" && number === "0") return;
    if (sourceAmount === "0" && number !== ".") {
      setSourceAmount(number);
      return;
    }
    // Prevent multiple decimal points
    if (number === "." && sourceAmount.includes(".")) return;
    if (
      (sourceAmount.charAt(sourceAmount.length - 1) === "." ||
        sourceAmount.length === 0) &&
      number === "."
    )
      return;
    setSourceAmount(sourceAmount + number);
  };

  const handleBackspaceClick = () => {
    setSourceAmount((prevAmount) => prevAmount.slice(0, -1));
  };

  // unformattedBalance - decimal; balance - big number
  const handlePresetClick = (preset) => {
    let unformattedBalance;
    if (fromToken.address != "0xEnclave") {
      unformattedBalance =
        sourceNetworkBalances[fromToken.address.toLowerCase()]?.total;
    } else {
      unformattedBalance = (
        parseFloat(smartBalanceObject.netBalance) / 1e6
      ).toString();
    }
    if (preset === "MAX" || preset === 100) {
      setSourceAmount(unformattedBalance);
    } else {
      const balance = ethers.parseUnits(
        unformattedBalance || "0",
        fromToken.decimals
      );
      const amount = (balance * ethers.toBigInt(preset)) / ethers.toBigInt(100); // Divide by 100 with same precision
      console.log(
        "About to transfer amount:",
        ethers.formatUnits(amount, fromToken.decimals)
      );
      setSourceAmount(ethers.formatUnits(amount, fromToken.decimals));
    }
  };

  return (
    <div className={(styles.page, styles.pageOverrides)}>
      <div
        style={{
          padding: "8px 20px 0 20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <span onClick={() => window.history.back()}>
            <ArrowBack />
          </span>
          <h2>Transfer</h2>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2rem",
            flexDirection: "column",
          }}
        >
          <div>
            <input
              className={styles.swapInput}
              value={sourceAmount}
              type="text"
              inputMode="decimal"
              readOnly // Disable typing from native keyboard
              placeholder="0"
              onChange={(e) => {
                let value = e.target.value;
                console.log("VALUE: ", value);
                if (value.startsWith("00")) {
                  value = value.slice(value.indexOf("0") + 1);
                } else if (value.startsWith("-")) {
                  value = "0";
                }
                if (isNaN(value)) {
                  value = "";
                }
                setSourceAmount(value);
              }}
              style={{
                border: "none",
                background: "transparent",
                fontSize: "3rem",
                color: "white",
                width: `${sourceAmount?.length + 1}ch`,
                maxWidth: "180px",
                minWidth: "2ch",
                fontWeight: "bold",
                // Dynamic width based on content
                textAlign: "right",
              }}
            />
            <span
              className={styles.swapInputSymbol}
              style={{
                fontSize: "3rem",
                color: "gray",
                marginLeft: "10px",
                whiteSpace: "nowrap",
                width: "60%",
                fontWeight: "bold",
              }}
            >
              {fromToken?.symbol}
            </span>
          </div>

          <div className={styles.swapBalanceText}>
            <small>
              Balance:&nbsp;
              {fromToken &&
                (fromToken.address === "0xEnclave"
                  ? smartBalanceObject.netBalance / 1e6
                  : sourceNetworkBalances[
                      fromToken?.chainIds
                        ?.find((chain) => chain.chainId === sourceNetwork.id)
                        ?.address?.toLowerCase()
                    ]
                  ? sourceNetworkBalances[
                      fromToken?.chainIds
                        ?.find((chain) => chain.chainId === sourceNetwork.id)
                        ?.address?.toLowerCase()
                    ]?.total
                  : 0)}
            </small>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: "12vh",
            padding: "20px",
            left: "0px",
          }}
        >
          {fromToken && (
            <SwapTokenSelect
              cashTransferMode={cashTransferMode}
              setCashTransferMode={setCashTransferMode}
              amount={sourceAmount}
              type={"Transfer"}
              selectedToken={fromToken}
              setSelectedToken={setFromToken}
              tokenList={destinationTokenList}
              balances={sourceNetworkBalances}
              networkList={networkList}
              selectedNetwork={sourceNetwork}
              setSelectedNetwork={setSourceNetwork}
              changeAllowed={true}
            />
          )}
          <div
            style={{
              marginBottom: "20px",
              border: "1px solid #575757",
              ...(validToAddress && {
                border: "1px solid var(--yellow-primary)",
              }),
            }}
            className={styles.swapInputWrapper}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                justifyContent: "space-between",
              }}
            >
              <label>To</label>
              {validToAddress && <BadgeCheck size={16} />}
            </div>
            <div style={{ display: "flex" }}>
              <input
                className={`${styles.swapInput}`}
                value={address}
                type="text"
                placeholder="Enter username or wallet address"
                onChange={(e) => {
                  handleAddressChange(e.target.value.toLowerCase());
                }}
                style={{ fontSize: 20 }}
              />
            </div>
          </div>

          <Numpad
            tokenSymbol={fromToken?.symbol}
            onNumberClick={handleNumberClick}
            onBackspaceClick={handleBackspaceClick}
            onPresetClick={handlePresetClick}
          />
          <button
            style={{ fontSize: "1.1rem" }}
            className="btn-primary w-full"
            disabled={!validSend}
            onClick={() => handleSubmit(addressToSend)}
          >
            {loading || usernameCheckLoading ? (
              <CircularProgress size={17} color="inherit" />
            ) : validSend ? (
              "Send"
            ) : !validToAddress ? (
              "Invalid address"
            ) : !validAmount ? (
              "Enter transfer amount"
            ) : !withinBalance || error ? (
              "Insufficient balance - Deposit more funds"
            ) : paymasterInsolvent ? (
              "Solver is broke "
            ) : (
              "Unknown error"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TransferPage;

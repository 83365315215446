import { useEffect, useRef, useState } from "react";
import styles from "./css/transfer.module.css";
import NetworkDropdown from "./Components/NetworkDropDowns";
import { getFromTokenList } from "../../utils/bungeeFunction";
import { processBalances2 } from "../../utils/functions";
import arb from "./assets/crypto/ARB.png";
import op from "./assets/crypto/OP.png";
import Dropdown from "./Components/TokenDropdown";
import { useEnclaveApi } from "./context/EnclaveConnectProvider";
import CircularProgress from "@mui/material/CircularProgress";
import { getERC20TransferCallData } from "./EnclaveUtils/functions";
import { debounce } from "lodash";
import { ethers } from "ethers";
import { networkDetailsByNameKey } from "../../utils/constants";
import isPWA from "../../utils/pwaUtils";
import { convertToNetworkTokenList } from "../../utils/tokenListUtils";

const networkList = Object.values(networkDetailsByNameKey);

export default function Transfer(props) {
  const { balances, allTokensList } = useEnclaveApi();
  const [sourceNetwork, setSourceNetwork] = useState({
    id: 42161,
    name: "Arbitrum",
    logo: arb,
  });

  const sourceTokenList = convertToNetworkTokenList(
    sourceNetwork.id,
    allTokensList
  );

  const [sourceNetworkBalances, setSourceNetworkBalances] = useState({});
  const [calldata, setCalldata] = useState("0x");

  const [fromToken, setFromToken] = useState(sourceTokenList?.[0]);

  const [sourceAmount, setSourceAmount] = useState();
  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState("");

  const validAmount = parseFloat(sourceAmount) > 0;
  const withinBalance =
    parseFloat(sourceAmount) <=
    parseFloat(sourceNetworkBalances[fromToken?.address.toLowerCase()]?.total);
  const validToAddress = ethers.isAddress(address);
  const validSend = validAmount && withinBalance && validToAddress;

  const bg = useRef(null);

  useEffect(() => {
    bg.current.addEventListener("click", (e) => {
      if (e.target === bg.current) {
        props.showTransfer(false);
      }
    });
  }, []);

  useEffect(() => {
    const balanceMap = processBalances2(
      balances.filter((balance) => balance.chainId === sourceNetwork.id)
    );
    console.log("BALANCE MAP: ", balanceMap);
    setSourceNetworkBalances(balanceMap);
  }, [balances, sourceNetwork]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          fromToken.address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        ) {
          setCalldata("0x");
        }
        if (sourceAmount) {
          const calldata = getERC20TransferCallData(
            sourceAmount,
            fromToken.address,
            address,
            fromToken.decimals
          );
          setCalldata(calldata);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    if (fromToken) {
      const fetchDataDebounce = debounce(fetchData, 500);
      setLoading(true);
      fetchDataDebounce();
    }
  }, [sourceAmount, fromToken, address]);

  const handleSubmit = () => {
    if (fromToken.address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
      const value = ethers.parseEther(sourceAmount);
      window.enclave.initiateTransaction(
        [
          {
            label: "Send " + fromToken.symbol,
            calldata: "0x",
            value: value,
            targetContractAddress: address,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
          },
        ],
        `Send ${sourceAmount} ${fromToken.symbol} to ${
          address.substring(0, 6) +
          "..." +
          address.substring(address.length - 4)
        }`
      );
    } else {
      window.enclave.initiateTransaction(
        [
          {
            label: "Send " + fromToken.symbol,
            calldata: calldata,
            targetContractAddress: fromToken.address,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
          },
        ],
        `Send ${sourceAmount} ${fromToken.symbol} to ${
          address.substring(0, 6) +
          "..." +
          address.substring(address.length - 4)
        }`
      );
    }
  };

  return (
    <div ref={bg} className={styles.main}>
      <div
        className={`${styles.popUp} ${isPWA() ? styles.popUpOverrides : ""}`}
      >
        <div className={styles.header2}>
          <img src="/logos/enclave.svg" alt="close" />
          <div>
            {/* <button>
							<img src="/icons/setting.svg" />
						</button> */}
          </div>
        </div>

        <div className={styles.header}>
          <img
            onMouseDown={() => {
              props.setWalletView();
            }}
            src="/icons/back.svg"
            alt="close"
          />
          <div className={styles.headerTitle}>
            <h2>{"Transfer"}</h2>
          </div>
        </div>

        {/*New SWAP UI */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            width: "100%",
          }}
        >
          <div className={styles.swapInputWrapper}>
            <div style={{ width: "fit-content" }}>
              <NetworkDropdown
                networkList={networkList}
                selectedNetwork={sourceNetwork}
                setSelectedNetwork={setSourceNetwork}
              />
            </div>
            <div style={{ display: "flex" }}>
              <input
                className={styles.swapInput}
                value={sourceAmount}
                type="number"
                inputMode="decimal"
                placeholder="0.00"
                onChange={(e) => {
                  let value = e.target.value;
                  console.log("VALUE: ", value);
                  if (value.startsWith("00")) {
                    value = value.slice(value.indexOf("0") + 1);
                  } else if (value.startsWith("-")) {
                    value = "0";
                  }
                  if (isNaN(value)) {
                    value = "";
                  }
                  setSourceAmount(value);
                }}
              />
              {fromToken && (
                <Dropdown
                  selectedToken={fromToken}
                  setSelectedToken={setFromToken}
                  tokenList={sourceTokenList}
                  balances={sourceNetworkBalances}
                />
              )}
            </div>
            <small>
              Balance:&nbsp;
              {fromToken &&
              sourceNetworkBalances[fromToken.address.toLowerCase()]
                ? parseFloat(
                    sourceNetworkBalances[fromToken.address.toLowerCase()]
                      ?.total
                  )
                : 0}
            </small>
          </div>
          <div className={styles.swapInputWrapper}>
            {/* <div style={{ width: "fit-content" }}>
							<NetworkDropdown
								networkList={networkList}
								selectedNetwork={destinationNetwork}
								setSelectedNetwork={setDestinationNetwork}
							/>
						</div> */}
            <label>To</label>
            <div style={{ display: "flex" }}>
              <input
                className={styles.swapInput}
                value={address}
                type="text"
                placeholder="Enter wallet address"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                style={{ fontSize: 20 }}
              />
            </div>
          </div>
          <button
            style={{ fontSize: "1.1rem" }}
            disabled={!validSend}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : validSend ? (
              "Send"
            ) : !validAmount ? (
              "Enter transfer amount"
            ) : !withinBalance ? (
              "Insufficient balance"
            ) : !validToAddress ? (
              "Invalid address"
            ) : (
              "Unknown error"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import accountStyles from "../css/account.module.css";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { Copy, CopyCheck } from "lucide-react";
import QRCodeStyling from "qr-code-styling";
import EndevourLogo from "../endeavour-192.png";

import crypto from "../EnclaveSDK/assets/crypto/crypto.png";
import NetworkModal from "../networkModal/networkModal";
import toast from "react-hot-toast";

const QRCodeComponent = ({ data }) => {
  const qrCodeRef = useRef(null);

  useEffect(() => {
    const qrCode = new QRCodeStyling({
      width: 250,
      height: 250,
      image: EndevourLogo,
      type: "svg",
      dotsOptions: {
        color: "#fcff61",
        type: "extra-rounded",
      },
      cornersSquareOptions: {
        type: "extra-rounded",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 20,
      },
      backgroundOptions: {
        color: "transparent", // Set background to transparent
      },
    });

    if (data) {
      qrCode.update({
        data: data,
      });
      qrCode.append(qrCodeRef.current);
    }

    return () => {
      if (qrCodeRef.current) {
        qrCodeRef.current.innerHTML = ""; // Clean up on unmount
      }
    };
  }, [data]);

  return <div ref={qrCodeRef} className={accountStyles.qrCode}></div>;
};

function AccountPage() {
  const { userData } = useEnclaveApi();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <div className={accountStyles.page}>
      <div className={accountStyles.header}>
        <span onClick={() => navigate(-1)}>
          <ArrowBack />
        </span>
        <h2 className={accountStyles.heading}>Accounts</h2>
      </div>
      <div className={accountStyles.walletContainer}>
        <div className={accountStyles.connectedAccount}>
          <div className={accountStyles.qrContainer}>
            <h2>Wallet Address</h2>
            <div className={accountStyles.copyDiv}>
              {userData.wallet?.scw_address.slice(0, 6) +
                "..." +
                userData.wallet?.scw_address.slice(-4)}
              {/* <Copy size={22} /> */}
            </div>
            <div>
              <QRCodeComponent data={userData.wallet?.scw_address} />
            </div>
            <br />
            <p className={accountStyles.walletDescription}>
              Send USDC to this address on any supported chain.
            </p>

            {/* <img width={130} src={crypto} alt="wallet-image" /> */}

            <p
              onClick={() => setIsModalOpen(true)}
              className={accountStyles.showNetworks}
            >
              Show Networks
            </p>
            <button
              onClick={() => {
                navigator.clipboard.writeText(userData.wallet?.scw_address);
                toast.success("Address Copied!", {
                  style: {
                    fontSize: "1rem",
                    borderRadius: "50px",
                    background: "var(--gray-800)",
                    color: "#fff",
                    border: "1px solid var(--gray-600)",
                  },
                });
                setCopied(true);
              }}
              style={{ width: "90%", marginTop: "10px" }}
              className={"btn-primary"}
            >
              {copied ? <CopyCheck size={20} /> : <Copy size={20} />}
              {copied ? "Address Copied!" : "Copy Address"}
            </button>

            {/* Modal Component */}
            <NetworkModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountPage;

import { CircularProgress } from "@mui/material";
import { ethers } from "ethers";
import { useEffect, useReducer, useState } from "react";
import {
  approveAddressERC20_CallData,
  transferERC20_CallData,
} from "../../utils/aaveFunction";
import { processBalances2 } from "../../utils/functions";
import styles from "./common.module.css";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import NetworkDropdown from "../SwapNetworkDropdown";
import Dropdown from "../SwapTokenDropdown";
import { debounce } from "lodash";
import { networkDetailsByNameKey } from "../../utils/constants";
import { networkDetails } from "../EnclaveSDK/EnclaveUtils/constants";
import { enabledNetworks } from "../EnclaveSDK/EnclaveUtils/constants";
import isPWA from "../../utils/pwaUtils";
import { getAggregatedQuote } from "../../utils/swapAdapter";
import { convertToNetworkTokenList } from "../../utils/tokenListUtils";
import { useLocation, useNavigate } from "react-router-dom";
import SwapTokenSelect from "../SwapTokenSelect";
import Numpad from "../Numpad";
import DepositFundModal from "../depositFundModal/depositFundModal";
import {
  computeQuote,
  getSmartBalanceConvertCalldata,
} from "../EnclaveSDK/EnclaveUtils/functionsV3";

import { ArrowBack } from "@mui/icons-material";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const replaceUSDC = (str) => str?.replace(/USDC/g, "USD");

const networkList = Object.values(networkDetailsByNameKey);

const initialSwapState = {
  sourceAmount: "",
  adjustedInputAmount: "",
  destinationAmount: 0,
  swapCallData: {},
  loading: false,
  sourceNetwork: null,
  destinationNetwork: null,
  fromToken: null,
  toToken: null,
  sourceNetworkBalances: {},
  destinationNetworkBalances: {},
  isDepositFundModalOpen: false
};

const swapReducer = (state, action) => {
  switch (action.type) {
    case 'SET_AMOUNT':
      return {
        ...state,
        sourceAmount: action.payload,
        loading: action.payload > 0 // Set loading when amount changes
      };
    case 'SET_QUOTE_DATA':
      return {
        ...state,
        adjustedInputAmount: action.payload.adjustedInputAmount,
        swapCallData: action.payload.swapCallData,
        destinationAmount: action.payload.destinationAmount,
        loading: false
      };
    case 'SET_NETWORKS':
      return {
        ...state,
        sourceNetwork: action.payload.sourceNetwork,
        destinationNetwork: action.payload.destinationNetwork
      };
    case 'SET_TOKENS':
      return {
        ...state,
        fromToken: action.payload.fromToken,
        toToken: action.payload.toToken
      };
    case 'SET_BALANCES':
      return {
        ...state,
        sourceNetworkBalances: action.payload.sourceNetworkBalances,
        destinationNetworkBalances: action.payload.destinationNetworkBalances
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'TOGGLE_DEPOSIT_MODAL':
      return {
        ...state,
        isDepositFundModalOpen: action.payload
      };
    case 'RESET':
      return initialSwapState;
    case 'SET_NETWORK_BALANCES':
      return {
        ...state,
        sourceNetworkBalances: action.payload.sourceNetworkBalances,
        destinationNetworkBalances: action.payload.destinationNetworkBalances
      };
    case 'SET_ADJUSTED_INPUT':
      return {
        ...state,
        adjustedInputAmount: action.payload
      };
    case 'SET_SWAP_DATA':
      return {
        ...state,
        swapCallData: action.payload.swapCallData,
        destinationAmount: action.payload.destinationAmount
      };
    default:
      return state;
  }
};

export default function SwapPage() {
  const {
    allTokensList,
  } = useEnclaveApi();
  if (!allTokensList?.length) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '90vh' 
      }}>
        <CircularProgress color="inherit" />
      </div>
    );
  } else {
    return <SwapPageMain />
  }
}

export function SwapPageMain() {
  const {
    walletAddress,
    balances,
    allTokensList,
    balancesLoading,
    smartBalanceObject,
  } = useEnclaveApi();

  const netBalanceDecimals = parseFloat(ethers.formatUnits(smartBalanceObject.netBalance, 6));

  const query = useQuery();
  const navigate = useNavigate();
  const psourceNetwork = query.get("psourceNetwork");
  const pdestinationNetwork = query.get("pdestinationNetwork");

  const psourceAddress = query.get("psourceAddress");
  const pdestinationAddress = query.get("pdestinationAddress");

  const type = query.get("txntype");

  const initialSourceNetwork = psourceNetwork
    ? networkDetails[parseInt(psourceNetwork)]
    : networkDetails[enabledNetworks[0]];
  const initialDestNetwork = pdestinationNetwork
    ? networkDetails[parseInt(pdestinationNetwork)]
    : networkDetails[enabledNetworks[1]];

  const sourceTokenList = convertToNetworkTokenList(
    initialSourceNetwork.id,
    allTokensList
  );
  const destinationTokenList = convertToNetworkTokenList(
    initialDestNetwork.id,
    allTokensList
  );

  const [state, dispatch] = useReducer(swapReducer, {
    ...initialSwapState,
    sourceNetwork: initialSourceNetwork,
    destinationNetwork: initialDestNetwork,
    fromToken: !!psourceAddress
      ? sourceTokenList?.find(
          (token) => token.address.toLowerCase() === psourceAddress.toLowerCase()
        )
      : sourceTokenList?.[0],
    toToken: !!pdestinationAddress
      ? destinationTokenList?.find(
          (token) => token.address.toLowerCase() === pdestinationAddress.toLowerCase()
        )
      : destinationTokenList?.[0]
  });

  const {
    sourceAmount,
    adjustedInputAmount,
    destinationAmount,
    swapCallData,
    loading,
    sourceNetwork,
    destinationNetwork,
    fromToken,
    toToken,
    sourceNetworkBalances,
    destinationNetworkBalances,
    isDepositFundModalOpen
  } = state;

  const setSourceNetwork = (network) => dispatch({ type: 'SET_NETWORKS', payload: { sourceNetwork: network, destinationNetwork: state.destinationNetwork } });
  const setDestinationNetwork = (network) => dispatch({ type: 'SET_NETWORKS', payload: { sourceNetwork: state.sourceNetwork, destinationNetwork: network } });
  const setFromToken = (token) => dispatch({ type: 'SET_TOKENS', payload: { fromToken: token, toToken: state.toToken } });
  const setToToken = (token) => dispatch({ type: 'SET_TOKENS', payload: { fromToken: state.fromToken, toToken: token } });
  const setSourceAmount = (amount) => dispatch({ type: 'SET_AMOUNT', payload: amount });
  const setLoading = (loading) => dispatch({ type: 'SET_LOADING', payload: loading });
  const setIsDepositFundModalOpen = (isOpen) => dispatch({ type: 'TOGGLE_DEPOSIT_MODAL', payload: isOpen });

  const setNetworkBalances = (source, destination) => 
    dispatch({ 
      type: 'SET_NETWORK_BALANCES', 
      payload: { 
        sourceNetworkBalances: source, 
        destinationNetworkBalances: destination 
      } 
    });

  const setAdjustedInputAmount = (amount) => 
    dispatch({ type: 'SET_ADJUSTED_INPUT', payload: amount });

  const setSwapData = (callData, destAmount) => 
    dispatch({ 
      type: 'SET_SWAP_DATA', 
      payload: { 
        swapCallData: callData, 
        destinationAmount: destAmount 
      } 
    });

  const validAmount = parseFloat(sourceAmount) > 0;
  const withinBalance =
    type === "BUY"
      ? parseFloat(sourceAmount) <= netBalanceDecimals
      : parseFloat(sourceAmount) <=
        parseFloat(
          sourceNetworkBalances[fromToken?.address.toLowerCase()]?.total
        );
  const validSwap = validAmount && withinBalance;

  const txnChainId = type === "BUY" ? destinationNetwork.id : sourceNetwork.id;
  const balanceByChainId =
    parseInt(
      smartBalanceObject.balanceByNetwork.find(
        (balanceObj) => balanceObj.network === txnChainId
      )?.value ?? 0
    ) / 1000000;
  const deficit =
    type === "BUY"
      ? parseFloat(sourceAmount) - balanceByChainId < 0
        ? 0
        : parseFloat(sourceAmount) - balanceByChainId
      : 0;
  const orderData =
    type === "BUY"
      ? {
          amount: Math.floor(sourceAmount * 1000000),
          type: "AMOUNT_IN",
        }
      : undefined;

  useEffect(() => {
    let newFromToken = sourceTokenList?.[0];

    const tempDestinationTokenList = destinationTokenList.filter((token) => {
      if (fromToken && fromToken.chainId === token.chainId) {
        return token.symbol !== fromToken.symbol;
      }
      return true;
    });

    let newToToken = tempDestinationTokenList?.[0];

    if (fromToken) {
      sourceTokenList.forEach((token) => {
        if (
          token.symbol === fromToken.symbol &&
          token.name === fromToken.name
        ) {
          newFromToken = token;
        }
      });
    } else {
      if (psourceAddress) {
        newFromToken = sourceTokenList.find(
          (token) =>
            token.address.toLowerCase() === psourceAddress.toLowerCase()
        );
      }
    }

    if (toToken) {
      tempDestinationTokenList.forEach((token) => {
        if (token.symbol === toToken.symbol && token.name === toToken.name) {
          newToToken = token;
        }
      });
    } else {
      if (pdestinationAddress) {
        newToToken = tempDestinationTokenList.find(
          (token) =>
            token.address.toLowerCase() === pdestinationAddress.toLowerCase()
        );
      }
    }
    setFromToken(newFromToken);
    setToToken(newToToken);
    setNetworkBalances(
      processBalances2(
        balances.filter((balance) => balance.chainId === sourceNetwork.id)
      ),
      processBalances2(
        balances.filter((balance) => balance.chainId === destinationNetwork.id)
      )
    );
  }, [sourceNetwork, destinationNetwork, allTokensList]);

  useEffect(() => {
    if (walletAddress) {
      setNetworkBalances(
        processBalances2(
          balances.filter((balance) => balance.chainId === sourceNetwork.id)
        ),
        processBalances2(
          balances.filter(
            (balance) => balance.chainId === destinationNetwork.id
          )
        )
      );
    }
  }, [walletAddress, balances]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let actualInputAmount;

        if (type === "SELL") {
          actualInputAmount = sourceAmount;
          setAdjustedInputAmount(actualInputAmount);
        } else {
          const usdcQuote = await computeQuote(
            walletAddress,
            destinationNetwork.id,
            Math.floor(parseFloat(sourceAmount) * 1000000),
            "AMOUNT_IN"
          );

          console.log("usdc quote whadup: ", usdcQuote);
          console.log("FINAL: Amount being borrowed: ", usdcQuote.total_credit);
          console.log(
            "FINAL: Amount being withdrawn from vault: ",
            usdcQuote.userWithdrawal
          );
          console.log(
            "FINAL: Amount being spent on this chain: ",
            parseInt(usdcQuote.total_credit) +
              parseInt(usdcQuote.userWithdrawal)
          );

          actualInputAmount = ethers.formatUnits(
            parseInt(usdcQuote.total_credit) +
              parseInt(usdcQuote.userWithdrawal),
            6
          );
          console.log(
            "FINAL: Amount being spent on this chain (decimals): ",
            actualInputAmount
          );
          setAdjustedInputAmount(actualInputAmount);
        }

        const actualAmountBigInt = ethers.parseUnits(actualInputAmount, fromToken.decimals ?? 18);
        const feeMultiplier = ethers.parseUnits("0.995", 3); // 0.995 with 3 decimal places
        const adjustedSourceAmount = ethers.formatUnits(
          (actualAmountBigInt * feeMultiplier) / ethers.parseUnits("1", 3),
          fromToken.decimals ?? 18
        );

        console.log(
          "FINAL: Amount being swapped after fees: ",
          adjustedSourceAmount
        );
        console.log(
          "FINAL: Amount being swapped after fees (WEI): ",
          ethers.parseUnits(adjustedSourceAmount, fromToken.decimals ?? 18).toString()
        );

        const aggData = await getAggregatedQuote(
          ethers
            .parseUnits(
              adjustedSourceAmount.toString(),
              fromToken.decimals ?? 18
            )
            .toString(),
          sourceNetwork.id,
          fromToken.address,
          fromToken.symbol,
          destinationNetwork.id,
          toToken.address,
          toToken.symbol,
          walletAddress,
          walletAddress
        );
        console.log({ aggData });
        setSwapData(aggData.txn, parseFloat(aggData.amount.toString()) / Math.pow(10, toToken.decimals ?? 18));
      } catch (error) {
        console.error("Error fetching data:", error);
        setSwapData({}, 0);
      } finally {
        setLoading(false);
      }
    };
    const fetchDataDebounce = debounce(fetchData, 1000);
    if (sourceAmount && sourceAmount > 0) {
      setLoading(true);
      fetchDataDebounce();
    } else {
      setSwapData({}, 0);
    }

    return () => {
      fetchDataDebounce.cancel();
    };
  }, [sourceAmount, fromToken, toToken]);

  const handleSubmit = async () => {
    try {
      if (!window.enclave) {
        alert("Wallet is not connected");
        return;
      }

      // Convert adjustedInputAmount to BigInt with proper decimals
      const inputAmountBigInt = ethers.parseUnits(adjustedInputAmount.toString(), fromToken.decimals ?? 18);
      // Calculate 0.5% fee (5/1000)
      const feeNumerator = ethers.toBigInt(5);
      const feeDenominator = ethers.toBigInt(1000);
      const feeAmount = inputAmountBigInt * feeNumerator / feeDenominator;

      console.log("FINAL: Fee amount: ", feeAmount);

      if (
        fromToken.address.toLowerCase() ===
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        const feeTxn = {
          label: "Fees",
          calldata: "0x",
          targetContractAddress: process.env.REACT_APP_FEE_ADDRESS,
          value: feeAmount,
          chainId: sourceNetwork.id,
          walletAddress: window.enclave.address,
        };

        window.enclave.initiateTransaction(
          [
            feeTxn,
            {
              label: "Crosschain Swap",
              calldata: swapCallData.data,
              targetContractAddress: swapCallData.to,
              value: swapCallData.value,
              chainId: sourceNetwork.id,
              walletAddress: window.enclave.address,
            },
          ],
          `Swap ${sourceAmount} ${
            fromToken.symbol
          } to ${destinationAmount.toFixed(6)} ${toToken.symbol}`
        );
      } else {
        // Modify based on 0x response
        const transferCallData = transferERC20_CallData(
          process.env.REACT_APP_FEE_ADDRESS,
          fromToken.address,
          sourceNetwork.id,
          feeAmount
        );

        const adjustedSourceAmount = ethers.formatUnits(
          (ethers.parseUnits(adjustedInputAmount, fromToken.decimals ?? 18) * ethers.parseUnits("0.995", 3)) / ethers.parseUnits("1", 3),
          fromToken.decimals ?? 18
        );

        console.log("FINAL: Amount being swapped: ", adjustedSourceAmount);

        const calldataERC20 = approveAddressERC20_CallData(
          swapCallData.to,
          fromToken.address,
          sourceNetwork.id,
          ethers.parseUnits(
            adjustedSourceAmount.toString(),
            fromToken.decimals ?? 18
          )
        );

        let txnList = [
          {
            label: "Fees ",
            calldata: transferCallData,
            targetContractAddress: fromToken.address,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
            label: "pay fees with input token",
          },
          {
            label: "Approve " + fromToken.symbol,
            calldata: calldataERC20,
            targetContractAddress: fromToken.address,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
            label: "approve token for swap",
          },
          {
            label: "Swap",
            calldata: swapCallData.data,
            targetContractAddress: swapCallData.to,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
            label: "do the actual swap",
          },
        ];
        if (type === "SELL") {
          const depositCallData = getSmartBalanceConvertCalldata(
            walletAddress,
            destinationNetwork.id
          );
          txnList = [
            ...txnList,
            {
              labal: "Deposit in vault",
              calldata: depositCallData,
              targetContractAddress: walletAddress,
              chainId: destinationNetwork.id,
              walletAddress: window.enclave.address,
            },
          ];
          console.log("SELL: orderData: ", orderData);
        }

        console.log("DEFICIT: ", deficit);

        window.enclave.initiateTransaction(
          txnList,
          `Swap ${sourceAmount} ${
            fromToken.symbol
          } to ${destinationAmount.toFixed(6)} ${toToken.symbol}`,
          fromToken.address,
          feeAmount,
          orderData,
          deficit <= 0 ? "GASLESS" : "ABSTRACTED_INTERNAL"
        );
      }
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  const handleNumberClick = (number) => {
    // Handle initial zero

    console.log("S AMT: ", sourceAmount);
    if (sourceAmount === "0" && number === "0") return;
    if (sourceAmount === "0" && number !== ".") {
      setSourceAmount(number);
      return;
    }
    // Prevent multiple decimal points
    if (number === "." && sourceAmount.includes(".")) return;
    if (
      (sourceAmount.charAt(sourceAmount.length - 1) === "." || sourceAmount.length === 0) && 
      number === "."
    ) return;

    setSourceAmount(sourceAmount + number);
  };
  const handleBackspaceClick = () => {
    setSourceAmount(sourceAmount.slice(0, -1));
  };

  const handlePresetClick = (preset) => {
    const balance =
      type === "SELL"
        ? ethers.parseUnits(sourceNetworkBalances[fromToken.address.toLowerCase()]?.total, fromToken.decimals)
          || 0
        : smartBalanceObject.netBalance

    if (preset === "MAX") {
      setSourceAmount(balance.toString());
    } else {
      // Convert preset (10, 25, 50, 100) to decimal (0.1, 0.25, 0.5, 1.0)
      const amount = ethers.toBigInt(balance) * ethers.toBigInt(preset) / ethers.toBigInt(100);
      console.log("W AMOUNT: ", amount);
      setSourceAmount(ethers.formatUnits(amount.toString(), fromToken.decimals));
    }
  };

  return (
    <>
      {isPWA() ? (
        <div className={(styles.page, styles.pageOverrides)}>
          <div
            style={{
              padding: "10px 20px 0 20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <span onClick={() => window.history.back()}>
                  <ArrowBack />
                </span>
                <h2>Trade</h2>
              </div>

              <div style={{ width: "fit-content" }}>
                {type === "SELL" && (
                  <NetworkDropdown
                    networkList={networkList}
                    selectedNetwork={sourceNetwork}
                    setSelectedNetwork={setSourceNetwork}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "5rem",
                flexDirection: "column",
              }}
            >
              <div>
                <input
                  className={styles.swapInput}
                  value={sourceAmount}
                  type="text"
                  inputMode="decimal"
                  readOnly // Disable typing from native keyboard
                  placeholder="0"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/,/g, ".");
                    if (value.startsWith("00")) {
                      value = value.slice(value.indexOf("0") + 1);
                    } else if (value.startsWith("-")) {
                      value = "0";
                    }
                    if (isNaN(value)) {
                      value = "";
                    }
                    setSourceAmount(value);
                  }}
                  style={{
                    border: "none",
                    background: "transparent",
                    fontSize: "3rem",
                    color: "white",
                    width: `${sourceAmount?.length + 1}ch`,
                    minWidth: "2ch",
                    maxWidth: "180px",
                    fontWeight: "bold",
                    // Dynamic width based on content
                    textAlign: "right",
                  }}
                />
                <span
                  className={styles.swapInputSymbol}
                  style={{
                    fontSize: "3rem",
                    color: "gray",
                    marginLeft: "10px",
                    whiteSpace: "nowrap",
                    width: "60%",
                    fontWeight: "bold",
                  }}
                >
                  {replaceUSDC(fromToken?.symbol)}
                </span>
              </div>
              <div className={styles.swapBalanceText}>
                <small>
                  Balance:&nbsp;
                  {type === "BUY"
                    ? netBalanceDecimals
                    : fromToken &&
                      sourceNetworkBalances[fromToken.address.toLowerCase()]
                    ? 
                        sourceNetworkBalances[fromToken.address.toLowerCase()]
                          ?.total
                      
                    : 0}
                </small>
              </div>
            </div>

            <div style={{ marginTop: "50px" }}>
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  bottom: "12vh",
                  padding: "20px",
                  left: "0px",
                }}
              >
                {toToken && (
                  <div style={{ marginBottom: "20px" }}>
                    <SwapTokenSelect
                      amount={destinationAmount}
                      type={"Buy"}
                      selectedToken={toToken}
                      setSelectedToken={setToToken}
                      tokenList={destinationTokenList}
                      balances={destinationNetworkBalances}
                      networkList={networkList}
                      selectedNetwork={destinationNetwork}
                      setSelectedNetwork={setDestinationNetwork}
                    />
                  </div>
                )}
                <Numpad
                  tokenSymbol={fromToken?.symbol}
                  onNumberClick={handleNumberClick}
                  onBackspaceClick={handleBackspaceClick}
                  onPresetClick={handlePresetClick}
                />
                <div style={{ marginTop: "10px" }}>
                  <button
                    style={{ fontSize: "1.1rem" }}
                    className="btn-primary w-full"
                    disabled={
                      !walletAddress ||
                      !ethers.isAddress(walletAddress) ||
                      !validAmount || // Disable when amount is not valid
                      loading || // Disable while loading
                      balancesLoading || // Disable while balances are loading
                      (!validSwap && validAmount && withinBalance) // Only disable for invalid swaps that aren't due to insufficient balance
                    }
                    onClick={
                      !withinBalance ? () => navigate("/account") : handleSubmit
                    }
                  >
                    {walletAddress && ethers.isAddress(walletAddress) ? (
                      loading || balancesLoading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : validSwap ? (
                        "Continue"
                      ) : !validAmount ? (
                        "Enter an amount"
                      ) : !withinBalance ? (
                        "Insufficient balance - Deposit funds"
                      ) : (
                        "Invalid trade"
                      )
                    ) : (
                      "Deposit tokens to trade"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${styles.page} ${isPWA() ? styles.pageOverrides : ""}`}
        >
          <div className={styles.wrapper}>
            <h1>Swap</h1>
            <div className={styles.swapCard}>
              <div className={styles.swapInputWrapper}>
                <div style={{ width: "fit-content" }}>
                  <NetworkDropdown
                    networkList={networkList}
                    selectedNetwork={sourceNetwork}
                    setSelectedNetwork={setSourceNetwork}
                  />
                </div>
                <label>Sell</label>
                <div style={{ display: "flex" }}>
                  <input
                    className={styles.swapInput}
                    value={sourceAmount}
                    type="text"
                    inputMode="decimal"
                    placeholder="0"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/,/g, ".");
                      if (value.startsWith("00")) {
                        value = value.slice(value.indexOf("0") + 1);
                      } else if (value.startsWith("-")) {
                        value = "0";
                      }
                      if (isNaN(value)) {
                        value = "";
                      }
                      setSourceAmount(value);
                    }}
                  />

                  {fromToken && (
                    <Dropdown
                      selectedToken={fromToken}
                      setSelectedToken={setFromToken}
                      tokenList={sourceTokenList}
                      balances={sourceNetworkBalances}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: 16,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <div>
                    <small>
                      Balance:&nbsp;
                      {fromToken &&
                      sourceNetworkBalances[fromToken.address.toLowerCase()]
                        ? parseFloat(
                            sourceNetworkBalances[
                              fromToken.address.toLowerCase()
                            ]?.total
                          )
                        : 0}
                    </small>
                  </div>
                  <div>
                    &nbsp;
                    <button
                      className="btn-ghost sm"
                      onClick={() => {
                        parseFloat(
                          sourceNetworkBalances[fromToken.address.toLowerCase()]
                            ?.total
                        )
                          ? setSourceAmount(
                              parseFloat(
                                sourceNetworkBalances[
                                  fromToken.address.toLowerCase()
                                ]?.total
                              )
                            )
                          : setSourceAmount(0);
                      }}
                    >
                      MAX
                    </button>
                  </div>
                </div>
              </div>
              <div className={styles.swapInputWrapper}>
                <div style={{ width: "fit-content" }}>
                  <NetworkDropdown
                    networkList={networkList}
                    selectedNetwork={destinationNetwork}
                    setSelectedNetwork={setDestinationNetwork}
                  />
                </div>
                <label>Buy</label>
                <div style={{ display: "flex" }}>
                  <input
                    className={styles.swapInput}
                    type="number"
                    inputMode="decimal"
                    placeholder="0"
                    value={destinationAmount}
                    disabled
                  />
                  {toToken && (
                    <Dropdown
                      selectedToken={toToken}
                      setSelectedToken={setToToken}
                      tokenList={destinationTokenList.filter((token) => {
                        if (fromToken.chainId === token.chainId) {
                          return token.symbol !== fromToken.symbol;
                        }
                        return true;
                      })}
                      balances={destinationNetworkBalances}
                    />
                  )}
                </div>
                <small>
                  Balance:&nbsp;
                  {toToken &&
                  destinationNetworkBalances[toToken.address.toLowerCase()]
                    ? parseFloat(
                        destinationNetworkBalances[
                          toToken.address.toLowerCase()
                        ]?.total
                      )
                    : 0}
                </small>
              </div>

              <button
                className="btn-primary w-full"
                disabled={
                  !walletAddress ||
                  !ethers.isAddress(walletAddress) ||
                  !validAmount || // Disable when amount is not valid
                  loading || // Disable while loading
                  balancesLoading || // Disable while balances are loading
                  (!validSwap && validAmount && withinBalance) // Only disable for invalid swaps that aren't due to insufficient balance
                }
                onClick={
                  !withinBalance ? () => navigate("/account") : handleSubmit
                }
              >
                {walletAddress && ethers.isAddress(walletAddress) ? (
                  loading || balancesLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : validSwap ? (
                    "Swap"
                  ) : !validAmount ? (
                    "Enter an amount"
                  ) : !withinBalance ? (
                    "Insufficient balance"
                  ) : (
                    "Invalid swap"
                  )
                ) : (
                  "Sign in to swap"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      {isDepositFundModalOpen && (
        <DepositFundModal
          isOpen={isDepositFundModalOpen}
          onClose={() => setIsDepositFundModalOpen(false)}
        />
      )}
    </>
  );
}

import { useEffect, useState, useRef } from "react";
import styles from "./css/transaction.module.css";
import {
  getMultiGasFees,
  submitMultiTransaction,
  generateMultiTransactionOptions,
  verifyMultiTransaction
} from "./EnclaveUtils/functionsV3";
import {
  startAuthentication,
} from "@simplewebauthn/browser";
import { CircularProgress } from "@mui/material";
import { enabledNetworks, networkDetails } from "./EnclaveUtils/constants";
import {
  useEnclaveApi,
} from "./context/EnclaveConnectProvider";
import { processBalances } from "../../utils/functions";
import { isMember } from "../../utils/gasNFT";
import isPWA from "../../utils/pwaUtils";
import { getExplorerUrl } from "./EnclaveUtils/functions";
import { debounce } from "lodash";
import dollarlogo from "../../assets/dollar_jpeg 1.png";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { fetchTransactionReceipt } from "./EnclaveUtils/functions";

const spliceAddress = (address) => {
  return address.slice(0, 8) + "..." + address.slice(-4);
};

const waitOneSecond = () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 1000); // Wait for 1 second (1000 milliseconds)
  });
};

const gasModes = {
  NATIVE: "NATIVE",
  USDC: "USDC",
  USDT: "USDT",
  USDC_OVERRIDE: "USDC_OVERRIDE",
  USDT_OVERRIDE: "USDT_OVERRIDE",
  ETH_OVERRIDE: "ETH_OVERRIDE",
  INSUFFICIENT_BALANCE: "INSUFFICIENT_BALANCE",
  GASLESS: "GASLESS",
  ABSTRACTED_INTERNAL: "ABSTRACTED_INTERNAL",
};

const formatNumber = (num) => {
  const value = Number(num);
  if (value < 0.001 && value > 0) {
    return "<0.001";
  }
  return value.toLocaleString(undefined, { maximumFractionDigits: 3 });
};

export default function Transaction(props) {
  const navigate = useNavigate();
  const bg = useRef(null);
  const {
    walletAddress,
    fetchBalances,
    balances,
    userData,
    allTokensList,
    smartBalanceObject,
  } = useEnclaveApi();
  const [transactionState, setTransactionState] = useState("pending");
  const [gasFeeLoading, setGasFeeLoading] = useState(true);
  const [txnResult, setTxnResult] = useState({});
  const [txnStatus, setTxnStatus] = useState(0);
  const [viewInternal, setViewInternal] = useState(false);
  const [gasFeeError, setGasFeeError] = useState(false);
  const [txnTime, setTxnTime] = useState(0);
  const keyBalanceData = balances
    .map((balanceObj) => ({
      address: balanceObj.address,
      chainId: balanceObj.chainId,
      balance: balanceObj.balance,
    }))
    .sort((a, b) => {
      // Sort by chainId first, then by address
      if (a.chainId === b.chainId) {
        return a.address.localeCompare(b.address);
      }
      return a.chainId - b.chainId;
    });

  const [gasFeeObject, setGasFeeObject] = useState({
    NATIVE: 0,
    USDC: 0,
    USDT: 0,
    // sponsored: false
    sponsored: true, // Permenantly set to true for gasless transactions based on input token fee
  });

  const internalTxns = window.enclave.transactionDetails.internalTxns;
  const chainId = internalTxns[0].chainId;

  const chainDetails = networkDetails[chainId];

  const processedBalances = processBalances(
    balances.filter((balance) => enabledNetworks.includes(balance.chainId))
  );

  const canExecuteTransaction = gasFeeObject.sponsored;

  const calculateGasFees = async () => {
    setGasFeeLoading(true);

    // Calculate gas fee for ETH transactions
    const ethGasFeeResultPromise = getMultiGasFees(
      userData.username,
      window.enclave.address,
      internalTxns.map((txn) => ({
        encodedData: txn.calldata,
        targetContractAddress: txn.contractAddress,
        value: txn.value,
        label: txn.label,
      })),
      chainId,
      window.enclave.transactionDetails.gasMode ??
        (window.enclave.transactionDetails.orderData
          ? gasModes.ABSTRACTED_INTERNAL
          : gasModes.GASLESS),
      window.enclave.transactionDetails.orderData
    );

    const memberPromise = isMember(userData.wallet?.scw_address);

    let ethGasFeeResult, memberResult;

    try {
      console.log("A. CALCULATING GAS FEES");
      [ethGasFeeResult, memberResult] = await Promise.all([
        ethGasFeeResultPromise,
        memberPromise,
      ]);
      console.log("B. GAS FEES: ", ethGasFeeResult);
    } catch (error) {
      console.log("C. Error fetching gas fees: ", error);
      setGasFeeError(true);
      setGasFeeLoading(false);
      return;
    }

    setGasFeeObject({
      NATIVE: ethGasFeeResult.error
        ? parseFloat(ethGasFeeResult.value) / 10 ** 18
        : parseFloat(ethGasFeeResult.result) / 10 ** 18,
      sponsored: true, // Permenantly set to true for gasless transactions based on input token fee
    });

    setGasFeeLoading(false);
  };

  const debouncedCalculateGasFees = useRef(
    debounce(calculateGasFees, 500) // 500ms delay
  ).current;

  useEffect(() => {
    bg.current.addEventListener("click", (e) => {
      if (e.target === bg.current) {
        props.setTransactionPopUp(false);
      }
    });
  }, []);

  useEffect(() => {
    debouncedCalculateGasFees();

    return () => {
      debouncedCalculateGasFees.cancel(); // Cancel any pending debounced calls on cleanup
    };
  }, []);

  useEffect(() => {
    if (txnStatus === 3) {
      console.log("444. Balance Updated");
      console.log("NEW NET SB: ", smartBalanceObject);
      console.log("NEW KEY B: ", keyBalanceData);
      const update = async () => {
        setTransactionState("completed");
        toast.dismiss();
        toast.success(`Transaction confirmed in ${txnTime/1000}s`, {
          style: {
            fontSize: "1rem",
            borderRadius: "50px",
            background: "var(--gray-800)",
            color: "#fff",
            border: "1px solid var(--gray-600)",
          },
        });
        await waitOneSecond();
        toast.dismiss();
        toast.loading(`Updating balances`, {
          style: {
            fontSize: "1rem",
            borderRadius: "50px",
            background: "var(--gray-800)",
            color: "#fff",
            border: "1px solid var(--gray-600)",
          },
        });
        await waitOneSecond();
        await fetchBalances();
        toast.dismiss();
        props.setTransactionPopUp(false);
        navigate("/dashboard");
      };
      setTxnStatus(4);
      setTimeout(update, 500);
    }
  }, [txnTime]);

  const executeTransaction = () => {
    setTransactionState("processing");
    toast.loading("Processing transaction...", {
      style: {
        fontSize: "1rem",
        borderRadius: "50px",
        background: "var(--gray-800)",
        color: "#fff",
        border: "1px solid var(--gray-600)",
      },
    });

    setTxnStatus(1);
    console.log("111. Submitting Txn");
    const startTime = (new Date()).getTime();
    submitMultiTransaction(
      props.userData.username,
      internalTxns.map((txn) => ({
        encodedData: txn.calldata,
        targetContractAddress: txn.contractAddress,
        value: txn.value,
        label: txn.label,
      })),
      chainId,
      window.enclave.transactionDetails.label,
      window.enclave.transactionDetails.gasMode ??
        (window.enclave.transactionDetails.orderData
          ? gasModes.ABSTRACTED_INTERNAL
          : gasModes.GASLESS),
      window.enclave.transactionDetails.feeToken,
      window.enclave.transactionDetails.feeTokenAmount,
      window.enclave.transactionDetails.orderData,
      window.enclave.transactionDetails.metaData
    )
      .then((res) => {
        setTxnResult(res);
        setTxnStatus(2);
        console.log("222. Submitted Txn: ", res);
        fetchTransactionReceipt(res.txnHash, chainId).then(async (result) => {
          setTxnStatus(3);
          const endTime = (new Date()).getTime();
          setTxnTime(endTime - startTime);
          console.log("333. Confirmed Txn: ", result);
          // while (transactionState != "completed") {
          //   await waitOneSecond();
          //   fetchBalances();
          // }
        });
        window.dispatchEvent(
          new Event("completedTransaction", { txnRes: res })
        );
      })
      .catch((error) => {
        setTransactionState("completed");
        setTxnResult({
          error: true,
        });
        toast.dismiss();
        toast.error("Transaction failed: " + error.message, {
          style: {
            fontSize: "1rem",
            borderRadius: "50px",
            background: "var(--gray-800)",
            color: "#fff",
            border: "1px solid var(--gray-600)",
          },
        });
      });
  };

  const executeTransaction2 = () => {
    setTransactionState("processing");
    toast.loading("Building transaction...", {
      style: {
        fontSize: "1rem",
        borderRadius: "50px",
        background: "var(--gray-800)",
        color: "#fff",
        border: "1px solid var(--gray-600)",
      },
    });
  
    setTxnStatus(1);
    console.log("111. Submitting Txn");
    let startTime;
  
    // First generate the transaction options
    generateMultiTransactionOptions(
      props.userData.username,
      internalTxns.map((txn) => ({
        encodedData: txn.calldata,
        targetContractAddress: txn.contractAddress,
        value: txn.value,
        label: txn.label,
      })),
      chainId,
      window.enclave.transactionDetails.label,
      window.enclave.transactionDetails.gasMode ??
        (window.enclave.transactionDetails.orderData
          ? gasModes.ABSTRACTED_INTERNAL
          : gasModes.GASLESS),
      window.enclave.transactionDetails.feeToken,
      window.enclave.transactionDetails.feeTokenAmount,
      window.enclave.transactionDetails.orderData,
      window.enclave.transactionDetails.metaData
    )
      .then(async (opts) => {
        console.log("Registration Options: ", opts);
        toast.dismiss();
        toast.loading("Waiting for signature...", {
          style: {
            fontSize: "1rem",
            borderRadius: "50px",
            background: "var(--gray-800)",
            color: "#fff",
            border: "1px solid var(--gray-600)",
          },
        });
        // Get authentication response
        const attResp = await startAuthentication(opts);
        console.log("\n\nattResp: ", attResp);
        toast.dismiss();
        toast.loading("Processing transaction...", {
          style: {
            fontSize: "1rem",
            borderRadius: "50px",
            background: "var(--gray-800)",
            color: "#fff",
            border: "1px solid var(--gray-600)",
          },
        });
        startTime = (new Date()).getTime();
        // Verify the transaction
        return await verifyMultiTransaction(props.userData.username, attResp);
      })
      .then((res) => {
        setTxnResult(res);
        setTxnStatus(2);
        console.log("222. Submitted Txn: ", res);
        fetchTransactionReceipt(res.txnHash, chainId).then(async (result) => {
          setTxnStatus(3);
          const endTime = new Date().getTime();
          setTxnTime(endTime - startTime);
          console.log("333. Confirmed Txn: ", result);
        });
        
        window.dispatchEvent(new Event("completedTransaction", { txnRes: res }));
      })
      .catch((error) => {
        setTransactionState("completed");
        setTxnResult({
          error: true,
        });
        toast.dismiss();
        toast.error("Transaction failed: " + error.message, {
          style: {
            fontSize: "1rem",
            borderRadius: "50px",
            background: "var(--gray-800)",
            color: "#fff",
            border: "1px solid var(--gray-600)",
          },
        });
      });
  };

  return (
    <main ref={bg} className={styles.main}>
      <div
        className={`${styles.popUp} ${isPWA() ? styles.popUpOverrides : ""}`}
      >
        <div>
          {" "}
          {window.enclave.transactionDetails.label.includes("Swap") ? (
            window.enclave.transactionDetails.label.split(" ")[2] === "USDC" ? (
              <>
                <h3 className={styles.details}> You are buying </h3>

                <br></br>

                <div className={styles.transactionAmount}>
                  <div>
                    <h2>
                      {formatNumber(window.enclave.transactionDetails.label.split(" ")[4])}
                      {" "}
                      {window.enclave.transactionDetails.label.split(" ")[5]}
                    </h2>

                    <h3 className={styles.details} style={{ marginTop: "4px" }}>
                      for{" "}
                      ${formatNumber(window.enclave.transactionDetails.label.split(" ")[1])}
                    </h3>
                  </div>

                  <div className={styles.tokenLogoContainer}>
                    {window.enclave.transactionDetails.label.split(" ")[2] ===
                      "USD" && (
                      <img className={styles.tokenLogo} src={dollarlogo} />
                    )}
                    {window.enclave.transactionDetails.label.split(" ")[2] !=
                      "USD" && (
                      <img
                        className={styles.tokenLogo}
                        src={
                          window.enclave.transactionDetails.label.includes(
                            "Swap"
                          )
                            ? window.enclave.transactionDetails.label.split(
                                " "
                              )[2] === "USDC"
                              ? allTokensList.find(
                                  (token) =>
                                    token.symbol ===
                                    window.enclave.transactionDetails.label.split(
                                      " "
                                    )[5]
                                )?.logoURI
                              : allTokensList.find(
                                  (token) =>
                                    token.symbol ===
                                    window.enclave.transactionDetails.label.split(
                                      " "
                                    )[2]
                                )?.logoURI
                            : allTokensList.find(
                                (token) =>
                                  token.symbol ===
                                  window.enclave.transactionDetails.label.split(
                                    " "
                                  )[2]
                              )?.logoURI
                        }
                        alt={chainDetails.name}
                      />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <h3 className={styles.details}> You are selling </h3>

                <br></br>

                <div className={styles.transactionAmount}>
                  <div>
                    <h2>
                      {formatNumber(window.enclave.transactionDetails.label.split(" ")[1])}
                      {" "}
                      {window.enclave.transactionDetails.label.split(" ")[2]}
                    </h2>

                    <h3 style={{ marginTop: "2px" }} className={styles.details}>
                      for{" "}
                      ${formatNumber(window.enclave.transactionDetails.label.split(" ")[4])}
                    </h3>
                  </div>

                  <div className={styles.tokenLogoContainer}>
                    {window.enclave.transactionDetails.label.split(" ")[2] ===
                      "USD" && (
                      <img className={styles.tokenLogo} src={dollarlogo} />
                    )}
                    {window.enclave.transactionDetails.label.split(" ")[2] !=
                      "USD" && (
                      <img
                        className={styles.tokenLogo}
                        src={
                          window.enclave.transactionDetails.label.includes(
                            "Swap"
                          )
                            ? window.enclave.transactionDetails.label.split(
                                " "
                              )[2] === "USDC"
                              ? allTokensList.find(
                                  (token) =>
                                    token.symbol ===
                                    window.enclave.transactionDetails.label.split(
                                      " "
                                    )[5]
                                )?.logoURI
                              : allTokensList.find(
                                  (token) =>
                                    token.symbol ===
                                    window.enclave.transactionDetails.label.split(
                                      " "
                                    )[2]
                                )?.logoURI
                            : allTokensList.find(
                                (token) =>
                                  token.symbol ===
                                  window.enclave.transactionDetails.label.split(
                                    " "
                                  )[2]
                              )?.logoURI
                        }
                        alt={chainDetails.name}
                      />
                    )}
                  </div>
                </div>
              </>
            )
          ) : window.enclave.transactionDetails.label.includes("Send") ? (
            <>
              <h3 className={styles.details}> You are sending </h3>

              <br></br>

              <div className={styles.transactionAmount}>
                <h2>
                  {window.enclave.transactionDetails.label
                    .split(" ")
                    .splice(1)
                    .join(" ")}
                </h2>
                <div className={styles.tokenLogoContainer}>
                  {window.enclave.transactionDetails.label.split(" ")[2] ===
                  "USD" ? (
                    <img
                      alt="USD"
                      className={styles.tokenLogo}
                      src={dollarlogo}
                    />
                  ) : (
                    <img
                      className={styles.tokenLogo}
                      src={
                        window.enclave.transactionDetails.label.includes("Swap")
                          ? window.enclave.transactionDetails.label.split(
                              " "
                            )[2] === "USDC"
                            ? allTokensList.find(
                                (token) =>
                                  token.symbol ===
                                  window.enclave.transactionDetails.label.split(
                                    " "
                                  )[5]
                              )?.logoURI
                            : allTokensList.find(
                                (token) =>
                                  token.symbol ===
                                  window.enclave.transactionDetails.label.split(
                                    " "
                                  )[2]
                              )?.logoURI
                          : allTokensList.find(
                              (token) =>
                                token.symbol ===
                                window.enclave.transactionDetails.label.split(
                                  " "
                                )[2]
                            )?.logoURI
                      }
                      alt={chainDetails.name}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            window.enclave.transactionDetails.label
          )}
        </div>
        <br />
        {/* {window.enclave.transactionDetails.label.includes("Send") ? null : (
          <div className={styles.details}>
            <h3>Transaction Fees: </h3>

            <h3>
              {0.05 * Number(window.enclave.transactionDetails.label.split(" ")[4])}
              {window.enclave.transactionDetails.label.split(" ")[5]}
            </h3>
          </div>
        )} */}
        {viewInternal && (
          <>
            {internalTxns.map((txn, index) => (
              <div className={styles.internalContainer} key={index}>
                <div className={styles.internalHeading}>
                  {txn.label} ({index + 1} / {internalTxns.length})
                </div>
                <div className={styles.internalHeading}>
                  <h2> From </h2>
                  <h2> To </h2>
                </div>

                <div className={styles.transaction}>
                  <div className={styles.fromContainer}>
                    <div className={`${styles.circle} ${styles.blue}`}></div>
                    <h2>{spliceAddress(walletAddress)}</h2>
                  </div>

                  <img src="/extras/transfer.svg" alt="Transfer" />
                  <div className={styles.fromContainer}>
                    <div className={`${styles.circle} ${styles.lime}`}></div>
                    <h2>{spliceAddress(txn.contractAddress)}</h2>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {transactionState === "pending" &&
          !gasFeeLoading &&
          canExecuteTransaction && (
            <div className={styles.btnCon2}>
              <button
                className={"btn-primary"}
                onClick={executeTransaction2}
                disabled={gasFeeError}
                style={
                  gasFeeError ? { opacity: 0.5, cursor: "not-allowed" } : {}
                }
              >
                <span style={{ fontSize: "1.2rem" }}>
                  {gasFeeError ? "Transaction will fail" : "Confirm"}
                </span>
              </button>
            </div>
          )}
        {(transactionState === "processing" || gasFeeLoading) &&
          transactionState !== "completed" && (
            <div className={styles.btnCon}>
              <button className={styles.button3} disabled={true}>
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ color: "#111" }}
                />
              </button>
            </div>
          )}
        {transactionState === "completed" && txnResult.txnHash && (
          <div className={styles.btnCon}>
            <button
              // onClick={() => {
              //   window.open(
              //     `${getExplorerUrl(
              //       window.enclave.transactionDetails.internalTxns[0].chainId
              //     )}${txnResult.txnHash}`
              //   );
              // }}
              className={styles.button3}
            >
              <CircularProgress
                size={20}
                color="inherit"
                style={{ color: "#111" }}
              />
              {/* <h1>View on Explorer</h1>
              <img
                className={styles.arrow}
                src="/icons/arrow.svg"
                alt="Arrow"
              /> */}
            </button>
          </div>
        )}
        {transactionState === "completed" && txnResult.error && (
          <div>
            <h4>Error: {txnResult.error}</h4>
          </div>
        )}
      </div>
    </main>
  );
}

import {
  startAuthentication,
  startRegistration,
} from "@simplewebauthn/browser";
import axios from "axios";
import VAULT_ABI from "../abis/Vault.json";
import ACCOUNT_ABI from "../abis/Account.json";
import { tokens, USDC_CONTRACT_ADDRESS, vaultAddress } from "./constants";
import { RPC_URLS } from "./rpcUrls";
import { ethers } from "ethers";

const headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Authorization", `${process.env.REACT_APP_ENCLAVE_SDK_KEY}`);

const BASE_URL = process.env.REACT_APP_BASE_URL;
export const createAccount = async (username, inviteCode) => {
  const resp = await fetch(
    `${BASE_URL}/v3/webauthn/register/generate-options?username=${username}`,
    {
      method: "GET",
      headers: headers,
    }
  );

  let attResp;
  try {
    const opts = await resp.json();
    console.log("Resitration Options: ", opts);
    attResp = await startRegistration(opts);
  } catch (error) {
    throw error;
  }

  console.log("\n\nattResp: ", attResp);

  const verificationResp = await fetch(
    `${BASE_URL}/v3/webauthn/register/verify?username=${username}&deployWallet=true&inviteCode=${inviteCode}`,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(attResp),
    }
  );

  const verificationJSON = await verificationResp.json();

  console.log("verificationJSON: ", JSON.stringify(verificationJSON, null, 2));
  return verificationJSON;
};

export const generateMultiTransactionOptions = async (
  username,
  transactionDetails,
  network,
  label = undefined,
  gasMode = "ABSTRACTED_INTERNAL",
  feeToken = undefined,
  feeTokenAmount = undefined,
  orderData,
  metaData = null
) => {
  const resp = await fetch(
    `${BASE_URL}/v3/webauthn/transaction/generate-options`,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        username,
        transactionDetails,
        network,
        gasMode,
        orderData,
        label,
        feeToken,
        feeTokenAmount: feeTokenAmount?.toString(),
        metaData,
      }),
    }
  );

  const opts = await resp.json();
  return opts;
};

export const verifyMultiTransaction = async (username, authenticationResponse) => {
  const verificationResp = await fetch(
    `${BASE_URL}/v3/webauthn/transaction/verify?username=${username}`,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(authenticationResponse),
    }
  );

  if (!verificationResp.ok) {
    const errorMessage = await verificationResp.text();
    throw new Error(`Transaction failed: ${errorMessage}`);
  }

  return await verificationResp.json();
};

export const submitMultiTransaction = async (
  username,
  transactionDetails,
  network,
  label = undefined,
  gasMode = "ABSTRACTED_INTERNAL",
  feeToken = undefined,
  feeTokenAmount = undefined,
  orderData,
  metaData = null
) => {
  console.log(
    "submitTransaction: ",
    username,
    transactionDetails,
    network,
    orderData
  );

  let attResp;
  try {
    const opts = await generateMultiTransactionOptions(
      username,
      transactionDetails,
      network,
      label,
      gasMode,
      feeToken,
      feeTokenAmount,
      orderData,
      metaData
    );
    console.log("Registration Options: ", opts);
    attResp = await startAuthentication(opts);
  } catch (error) {
    throw error;
  }

  console.log("\n\nattResp: ", attResp);
  
  const verificationJSON = await verifyMultiTransaction(username, attResp);
  console.log("verificationJSON: ", JSON.stringify(verificationJSON, null, 2));
  return verificationJSON;
};

export const computeQuote = async (
  walletAddress,
  outputNetwork,
  amount,
  type,
  limit
) => {
  const resp = await axios.post(
    `${BASE_URL}/v3/smartbalance/getquote`,
    {
      walletAddress,
      outputNetwork,
      amount,
      type,
      limit,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${process.env.REACT_APP_ENCLAVE_SDK_KEY}`,
      },
    }
  );

  return resp.data;
};

export const getSmartBalance = async (walletAddress) => {
  const resp = await axios.get(
    `${BASE_URL}/v3/smartbalance/getbalance?walletAddress=${walletAddress}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${process.env.REACT_APP_ENCLAVE_SDK_KEY}`,
      },
    }
  );

  console.log("SMART BALANCE: ", resp.data);

  return resp.data;
};

export const get0Xquote = async (
  sellToken,
  buyToken,
  sellAmount,
  takerAddress,
  chainId
) => {
  const resp = await axios.get(
    `${BASE_URL}/api/zerox/quote?sellToken=${sellToken}&buyToken=${buyToken}&sellAmount=${sellAmount}&takerAddress=${takerAddress}&chainId=${chainId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${process.env.REACT_APP_ENCLAVE_SDK_KEY}`,
      },
    }
  );

  return resp.data;
};

export const getWithdrawFromVaultCalldata = (amount, chainId) => {
  const provider = new ethers.JsonRpcProvider(RPC_URLS[chainId]);
  const usdcAddress = tokens["USDC"][chainId];
  const vault = new ethers.Contract(vaultAddress, VAULT_ABI, provider);
  // ABI encode withdraw function in Vault contract
  const encodedData = vault.interface.encodeFunctionData("withdraw", [
    usdcAddress,
    amount,
  ]);
  return encodedData;
};

export const getDepositToVaultCalldata = (amount, chainId) => {
  const provider = new ethers.JsonRpcProvider(RPC_URLS[chainId]);
  const usdcAddress = tokens["USDC"][chainId];
  const vault = new ethers.Contract(vaultAddress, VAULT_ABI, provider);
  // ABI encode withdraw function in Vault contract
  const encodedData = vault.interface.encodeFunctionData("deposit", [
    usdcAddress,
    amount,
  ]);
  return encodedData;
};

export const getDepositAllToVaultCalldata = (chainId) => {
  const provider = new ethers.JsonRpcProvider(RPC_URLS[chainId]);
  const usdcAddress = tokens["USDC"][chainId];
  const vault = new ethers.Contract(vaultAddress, VAULT_ABI, provider);
  // ABI encode withdraw function in Vault contract
  const encodedData = vault.interface.encodeFunctionData("depositAll", [
    usdcAddress
  ]);
  return encodedData;
};

export const getSmartBalanceConvertCalldata = (walletAddress, chainId) => {
  const provider = new ethers.JsonRpcProvider(RPC_URLS[chainId]);
  const usdcAddress = tokens["USDC"][chainId];
  const account = new ethers.Contract(walletAddress, ACCOUNT_ABI, provider);
  // ABI encode withdraw function in Vault contract
  const encodedData = account.interface.encodeFunctionData(
    "smartBalanceConvert",
    [usdcAddress]
  );
  return encodedData;
};

export const getMultiGasFees = async (
  username,
  walletAddress,
  transactionDetails,
  network,
  gasMode,
  orderData
) => {
  const resp = await axios.post(
    `${BASE_URL}/v3/gas-fees`,
    {
      username,
      walletAddress,
      transactionDetails,
      network,
      gasMode,
      orderData,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${process.env.REACT_APP_ENCLAVE_SDK_KEY}`,
      },
    }
  );

  return resp.data;
};

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./common.module.css";
import accountStyles from "../css/account.module.css";
import { processBalances, processBalances3 } from "../EnclaveSDK/EnclaveUtils/functions";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import dollarlogo from "../../assets/dollar_jpeg 1.png";
import {
  getAddressExplorer,
} from "../EnclaveSDK/EnclaveUtils/constants";
import CircularProgress from "@mui/material/CircularProgress";
import {
  UserX,
  HandCoins,
  ArrowUp,
  ArrowDown,
  ScanLine,
} from "lucide-react"; // Change PiggyBank to Download
import {
  AccountBalance,
} from "@mui/icons-material";
import { enabledNetworks } from "../EnclaveSDK/EnclaveUtils/constants";
import link from "../EnclaveSDK/assets/icons/link.svg";
import isPWA from "../../utils/pwaUtils";
import ethLogo from "../EnclaveSDK/assets/crypto/ETH.png";
import avaxLogo from "../EnclaveSDK/assets/crypto/avalanche.png";

import dashboardStyles from "../EnclaveSDK/css/dashboard.module.css";
import Transfer from "../EnclaveSDK/Transfer";
import { formatPrice } from "../../utils/priceUtils";

import QrScanner from "qr-scanner";

export default function DashboardPage() {
  const {
    userData,
    balances,
    balancesLoading,
    portfolioValue,
    smartBalanceObject,
    allTokenMap
  } = useEnclaveApi();
  const [processedBalances, setProcessedBalances] = useState({});
  const [transfer, setTransfer] = useState(false);
  const [hideSmallBalances, setHideSmallBalances] = useState(true);
  const [showScanner, setShowScanner] = useState(false);
  const videoRef = useRef(null);
  const qrScannerRef = useRef(null);

  const navigate = useNavigate();

  const handleDepositClick = () => {
    navigate("/account");
  };

  const handleYieldClick = () => {
    navigate("/earn"); // Change this line
  };

  useEffect(() => {
    if (userData?.wallet?.scw_address) {
      const fetchBalances = async () => {
        try {
          const processedBalances = processBalances3(
            balances.filter(
              (balance) =>
                enabledNetworks.includes(balance.chainId) && balance.amount > 0
            ),
            allTokenMap
          );
          setProcessedBalances(processedBalances);
        } catch (error) {
          console.error("Error fetching balances:", error);
        }
      };
      fetchBalances();
    }
  }, [userData, balances]);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  useEffect(() => {
    if (showScanner && videoRef.current) {
      qrScannerRef.current = new QrScanner(
        videoRef.current,
        (result) => {
          // Assuming the QR code contains a wallet address
          navigate(`/transfer?pdestinationNetwork=10&pdestinationAddress=0xEnclave&wallet=${result.data}`);
          setShowScanner(false);
        },
        {
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );

      qrScannerRef.current.start();
    }

    return () => {
      if (qrScannerRef.current) {
        qrScannerRef.current.destroy();
      }
    };
  }, [showScanner]);

  return (
    <div
      className={`${styles.page} ${isPWA() ? styles.pageOverrides : ""}`}
      style={{
        maxWidth: "960px",
        margin: "auto",
        padding: "5px 14px 24px 14px",
        overflowY: "scroll",
      }}
    >
      {!userData || !userData.wallet?.scw_address ? (
        <div className={styles.disconnectedAccount}>
          <UserX size={32} />
          <p>Please connect your account to view details</p>
        </div>
      ) : (
        <>
          <div className={styles.portfolioValueContainer}>
            <span style={{ display: "block", marginBottom: "8px" }}>
              Welcome
            </span>
            <h1>{userData.username}</h1>
            <span style={{ display: "block", marginBottom: "8px" }}>
              Portfolio
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span style={{ fontSize: "3rem", fontWeight: "bold" }}>
                {portfolioValue.loading ? (
                  <CircularProgress sx={{ color: "black" }} />
                ) : (
                  `$${formatPrice(portfolioValue.value.toFixed(4))}`
                )}
              </span>
            </div>
          </div>

          <div className={dashboardStyles.addMoneyContainer}>
            <div
              className={dashboardStyles.ctaButtonWrapper}
              onClick={() => navigate("/account")}
            >
              <div className={dashboardStyles.circularIconWrapper}>
                <ArrowDown />
              </div>
              <span>Deposit</span>
            </div>

            <div
              className={dashboardStyles.ctaButtonWrapper}
              onClick={() => {
                isPWA()
                  ? navigate(
                      "/transfer?pdestinationNetwork=10&pdestinationAddress=0xEnclave"
                    )
                  : setTransfer(true);
              }}
            >
              <div className={dashboardStyles.circularIconWrapper}>
                <ArrowUp />
              </div>
              <span style={{ textAlign: "right" }}>Transfer</span>
            </div>
            <div
              className={dashboardStyles.qrButton}
              onClick={() => setShowScanner(true)}
            >
              <ScanLine />
            </div>
          </div>

          <div
            style={{
              paddingBottom:
                processedBalances && Object.keys(processedBalances).length > 0
                  ? "100px"
                  : "0px",
            }}
          >
            {balancesLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress sx={{ color: "white" }} />
              </div>
            ) : (processedBalances &&
                Object.keys(processedBalances).length > 0) ||
              parseFloat(smartBalanceObject.netBalance) > 0 ? (
              <div style={{ height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                    gap: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <HandCoins size={30} />
                    <h1 style={{ marginBottom: "0px" }}>Assets</h1>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <span
                      className={styles.toggleText}
                      style={{ fontSize: "14px" }}
                    >
                      Hide small balances
                    </span>
                    <div className={styles.toggleSwitch}>
                      <input
                        type="checkbox"
                        id="hideSmallBalances"
                        checked={hideSmallBalances}
                        onChange={(e) => setHideSmallBalances(e.target.checked)}
                      />
                      <label htmlFor="hideSmallBalances"></label>
                    </div>
                  </div>
                </div>
                <div className={styles.balancesTableWrapper}>
                  <table className={styles.balancesTable}>
                    <tbody>
                      {(parseFloat(smartBalanceObject.netBalance) / 1000000 >
                        0.01 ||
                        (!hideSmallBalances &&
                          parseFloat(smartBalanceObject.netBalance) / 1000000 <=
                            0.01)) && (
                        <tr key={"1"} className={styles.yieldRow}>
                          <td width={42}>
                            <div className={styles.logoWrapper}>
                              <img
                                width={48}
                                height={48}
                                src={dollarlogo}
                                alt={"USD"}
                              />
                            </div>
                          </td>
                          <td>
                            <h3 style={{ fontSize: "1.1rem" }}>Cash</h3>
                            <h5>{"USD"}</h5>
                          </td>
                          <td className={styles.priceInfo}>
                            {
                              <>
                                <span className={styles.tokenPrice}>
                                  {parseFloat(smartBalanceObject.netBalance) /
                                    1000000 <
                                  0.01
                                    ? "< $0.01"
                                    : `$${
                                    formatPrice(
                                        parseFloat(
                                          smartBalanceObject.netBalance
                                        ) / 1000000)
                                      }`}
                                </span>
                              </>
                            }
                          </td>
                        </tr>
                      )}
                      {Object.entries(processedBalances || {})
                        .filter(([symbol, token]) => {
                          return (!hideSmallBalances || token.totalUsd >= 0.01) && symbol != "USDC";
                        })
                        .map(([symbol, token]) => (
                          <BalanceRow
                            key={symbol}
                            symbol={symbol}
                            token={token}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div style={{ marginTop: "115px" }} className={styles.noBalances}>
                <AccountBalance sx={{ fontSize: 24 }} />
                <br />
                <h2>Welcome to Endeavour!</h2>
                <div>
                  Let's get started by depositing some assets to your account.
                  Click the button below to get started.
                </div>
                <br />
                <button onClick={handleDepositClick} className={"btn-primary"}>
                  Deposit Assets
                </button>
              </div>
            )}
          </div>

          {transfer && (
            <Transfer showTransfer={setTransfer} setWalletView={setTransfer} />
          )}

          {showScanner && (
            <div className={styles.scannerModal}>
              <div className={styles.scannerContent}>
                <button
                  className={styles.closeButton}
                  onClick={() => setShowScanner(false)}
                >
                  ✕
                </button>
                <video ref={videoRef} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

const BalanceRow = ({ symbol, token }) => {
  const logoURI =
    symbol === "ETH"
      ? ethLogo
      : symbol === "AVAX"
      ? avaxLogo
      : token?.logoURI || "";

  const navigate = useNavigate();

  const handleTokenClick = (symbol, index) => {
    sessionStorage.setItem("exploreTokenIndex", index.toString());
    navigate(`/explore/token/${symbol}`);
  };

  return (
    <>
      <tr
        key={symbol}
        className={styles.yieldRow}
        onClick={(event) => handleTokenClick(token.symbol, event)}
      >
        <td width={42}>
          <div className={styles.logoWrapper}>
            <img width={48} height={48} src={logoURI} alt={token.name} />
          </div>
        </td>
        <td>
          <h3 style={{ 
            fontSize: "1.1rem", 
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "200px"
          }}>{token.name}</h3>
          <h5>
            {formatPrice(parseFloat(token.total).toFixed(4))} {symbol}
          </h5>
        </td>
        <td className={styles.priceInfo}>
          {
            <>
              <span className={styles.tokenPrice}>
                {token.totalUsd < 0.01
                  ? "< $0.01"
                  : `$${formatPrice(token.totalUsd)}`}
              </span>
              <span
                className={`${styles.delta} ${
                  token.deltaAbs > 0 ? styles.gain : token.deltaAbs < 0 ? styles.loss : styles.neutral
                }`}
              >
                {token.deltaAbs > 0 ? <>&#x25B2;</> : <>&#x25BC;</>}
                {Math.abs(token.deltaAbs) < 0.01
                  ? "< $0.01"
                  : `$${formatPrice(token.deltaAbs)}`}
              </span>
            </>
          }
        </td>
      </tr>
    </>
  );
};

export const Network = (props) => {
  return (
    <div
      className={accountStyles.accountNetwork}
      onClick={() =>
        window.open(getAddressExplorer(props.network, props.address))
      }
    >
      <div className={accountStyles.accountNetworkLeft}>
        <img src={props.img} alt="" />
        <div>
          <p>{props.name}</p>
          <h2>
            {props.address.slice(0, 6) + "..." + props.address.slice(-4)}{" "}
            <img src={link} alt="" />
          </h2>
        </div>
      </div>
      <div className={accountStyles.accountNetworkRight}>
        <div className={accountStyles.deploymentBadgeContainerMobile}>
          <span
            className={`${accountStyles.deploymentBadge} ${
              props.deployed
                ? accountStyles.deployed
                : accountStyles.notDeployed
            }`}
          >
            {props.deployed ? "Deployed" : "Not Deployed"}
          </span>
        </div>
      </div>
    </div>
  );
};

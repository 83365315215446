import React, { useState, useRef, useEffect } from "react";
import styles from "./css/exploreDropdown.module.css"; // Import your CSS module
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AttachMoney, TrendingUp, TrendingDown, Star } from "@mui/icons-material";

const getIcon = (option) => {
    switch (option) {
        case "Trending":
            return <Star />;
        case "Gainers":
            return <TrendingUp />;
        case "Losers":
            return <TrendingDown />;
        case "Market Cap":
            return <AttachMoney />;
    }
}


const ExploreOptionsDropdown = (props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const selectOption = (option) => {
        props.setSelectedOption(option);
        setIsDropdownOpen(false); // Close dropdown after selection
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.container}>
            {/* Token Dropdown */}
            <div className={styles.dropdown} onClick={toggleDropdown}>
                {getIcon(props.selectedOption)}
                <span style={{ marginLeft: 8 }}>{props.selectedOption}</span>
                {/* <div className={styles.dropdownArrowBox}> */}
                    <KeyboardArrowDownIcon sx={{ fontSize: 16 }} />
                {/* </div> */}
            </div>
            {isDropdownOpen && (
                <ul className={styles.dropdownMenu} ref={dropdownRef}>
                    {props.optionList.map((option) => (
                        <li
                            key={option}
                            className={styles.dropdownItem}
                            onClick={() => {
                                selectOption(option);
                            }}
                        >
                            {getIcon(option)}
                            <span style={{ marginLeft: 8 }}>{option}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ExploreOptionsDropdown;

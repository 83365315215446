import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../pages/common.module.css";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import CircularProgress from "@mui/material/CircularProgress";
import isPWA from "../../utils/pwaUtils";
import { ArrowBack, MarkEmailReadTwoTone, Public } from "@mui/icons-material";
import {
  enabledNetworks,
  networkDetails,
  tokens,
} from "../EnclaveSDK/EnclaveUtils/constants";
import { getCompleteTokenDetails, getPriceHistory } from "../../utils/birdeye";
import ChartJSFilledLineChart from "../EnclaveSDK/Chart/LineChart";
import { X as XIcon } from "@mui/icons-material";
import { formatPrice } from "../../utils/priceUtils";
import { BuySellPopup } from "../pages/tokenDetailsSubComponents/buySellPopup";
import { processBalances } from "../../utils/functions";
import WarningIcon from "@mui/icons-material/Warning";
import {
  ChartLine,
  ChartNoAxesColumn,
  ChartPie,
  Send,
  Users,
} from "lucide-react";

const timeFrameToWindowMapping = {
  "1D": "5m",
  "1W": "30m",
  "1M": "2H",
  "1Y": "1D",
  All: "1D",
};

const getFromDate = (timeFrame) => {
  switch (timeFrame) {
    case "1D":
      return parseInt(
        new Date(Date.now() - 24 * 60 * 60 * 1000).getTime() / 1000
      ); // 1 day before
    case "1W":
      return parseInt(
        new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).getTime() / 1000
      ); // 1 week before
    case "1M":
      return parseInt(
        new Date(new Date().setMonth(new Date().getMonth() - 1)).getTime() /
          1000
      ); // 1 month before
    case "1Y":
      return parseInt(
        new Date(
          new Date().setFullYear(new Date().getFullYear() - 1)
        ).getTime() / 1000
      ); // 1 year before
    default:
      return 0;
  }
};

const getRep = (number, sigdig) => {
  if (number == null || number == undefined) {
    return "-";
  }

  if (number / 1000000000 >= 1) {
    return `${(number / 1000000000).toFixed(sigdig)}B`;
  } else if (number / 1000000 >= 1) {
    return `${(number / 1000000).toFixed(sigdig)}M`;
  } else if (number / 1000 >= 1) {
    return `${(number / 1000).toFixed(sigdig)}k`;
  } else {
    return number.toFixed(1);
  }
};

export default function TokenDetailsDrawer({ token }) {
  const { tokenListLoading } = useEnclaveApi();
  const navigate = useNavigate();

  if (tokenListLoading) {
    return (
      <div
        className={`${styles.page} ${isPWA() ? styles.pageOverrides : ""}`}
        style={{ maxWidth: "960px", margin: "auto", padding: "24px" }}
      >
        <div
          style={{
            height: "60vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }

  return <TokenDetails tokenSymbol={token.symbol} />;
}

export const BuySellModes = {
  BUY: "Buy",
  SELL: "Sell",
  NONE: "None",
};

const TokenDetails = ({ tokenSymbol }) => {
  const { userData, balances, allTokensList, tokenListLoading } =
    useEnclaveApi();

  //   const { tokenSymbol } = useParams();
  const [priceData, setPriceData] = useState([]);
  const [tokenOverview, setTokenOverview] = useState({});
  const [timeFrame, setTimeFrame] = useState("1D");
  const [loading, setLoading] = useState(false);
  const window = timeFrameToWindowMapping[timeFrame];
  const [mode, setMode] = useState(BuySellModes.NONE);
  const [hoveredPrice, setHoveredPrice] = useState(null);
  const [hoveredDelta, setHoveredDelta] = useState(null);
  const [hoveredDateTime, setHoveredDateTime] = useState(null);
  const chartRef = useRef(null);
  const [showFullDescription, setShowFullDescription] = useState(false);

  console.log("TOKEN OVERVIEW: ", tokenOverview);

  const priceList = priceData.map((obj) => obj.value);
  const timeList = priceData.map((obj) =>
    new Date(obj.unixTime * 1000).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  );

  const selectedToken = allTokensList.find(
    (token) => token.symbol === tokenSymbol
  );

  console.log("Selected Token: ", selectedToken);

  const processedBalances = processBalances(balances);
  const tokenBalance = processedBalances[selectedToken.symbol]?.total;

  const calculateDelta = (currentPrice, initialPrice) => {
    if (!!currentPrice && !!initialPrice) {
      return ((currentPrice / initialPrice - 1) * 100).toFixed(3);
    }
    return 0;
  };

  const delta =
    hoveredDelta !== null
      ? hoveredDelta
      : calculateDelta(
          priceData[priceData.length - 1]?.value,
          priceData[0]?.value
        );
  const gain = delta > 0;
  const loss = delta < 0;

  const network =
    networkDetails[selectedToken?.chainIds?.[0].chainId]?.birdeyeName;
  const address = selectedToken?.chainIds?.[0].address;
  const warning =
    selectedToken?.status?.allowance && !selectedToken?.status?.transferFrom;

  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedToken) {
      navigate("/explore");
    }
  }, []);



  useEffect(() => {
    setLoading(true);
    if (Object.keys(tokenOverview).length == 0) {
      getCompleteTokenDetails(
        address,
        window,
        getFromDate(timeFrame),
        parseInt(new Date().getTime() / 1000),
        network
      )
        .then((data) => {
          console.log({ data });
          setPriceData(data.priceData.data.items);
          setTokenOverview(data.overviewData.data);
        })
        .catch((e) => {
          console.log("Error Fetching Price Data: ", e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getPriceHistory(
        address,
        window,
        getFromDate(timeFrame),
        parseInt(new Date().getTime() / 1000),
        network
      )
        .then((data) => {
          setPriceData(data.data.items);
        })
        .catch((e) => {
          console.log("Error Fetching Price Data: ", e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedToken, timeFrame]);

  const formatDisplayPrice = (price) => {
    if (price === null || price === undefined) {
      return formatPrice(selectedToken.price);
    }
    return formatPrice(price);
  };

  const handleBackClick = () => {
    const savedScrollPosition = sessionStorage.getItem("exploreScrollPosition");
    navigate("/explore", {
      state: {
        scrollToPosition: savedScrollPosition
          ? parseInt(savedScrollPosition)
          : 0,
      },
    });
  };

  const formatDateTime = (unixTime) => {
    const date = new Date(unixTime * 1000);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const descriptionStyle = {
    display: "-webkit-box",
    WebkitLineClamp: showFullDescription ? "unset" : "4",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    marginBottom: "8px",
  };

  console.log(selectedToken, "****");

  return (
    <>
      <div
        className={`${styles.page} ${isPWA() ? styles.pageOverrides : ""}`}
        style={{
          maxWidth: "960px",
          margin: "auto",
          padding: " 10px 0px 0px 0px",
        }}
      >
        <div>
          {warning ? (
            <span className={styles.warning}>
              <WarningIcon sx={{ fontSize: "16px" }} /> This token may have
              issues with transferring.
            </span>
          ) : (
            <></>
          )}

          {/* <div className={styles.tokenExtensions}>
            {tokenOverview.extensions?.website && (
              <a
                href={tokenOverview.extensions.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>
                  <Public />
                </div>
              </a>
            )}
            {tokenOverview.extensions?.twitter && (
              <a
                href={tokenOverview.extensions.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>
                  <XIcon />
                </div>
              </a>
            )}
          </div> */}
        </div>
        <div
          style={{
            overflowY: "auto",
            height: "100%",
            scrollbarWidth: "none",
            paddingBottom: "20px",
          }}
        >
          <div style={{ padding: "0px 24px" }}>
            {" "}
            <div className={styles.basicTokenDetails}>
              <div className={styles.logoWrapper}>
                <img width={50} height={50} src={selectedToken.logoURI} />
              </div>
              <div className={styles.token}>
                <h3>{selectedToken.name}</h3>
                <h5>{selectedToken.symbol}</h5>
              </div>
            </div>
            <div className={styles.priceDataContainer}>
              {/* <span style={{color: "lightgray"}}>Current Price</span> */}
              <h1>
                <span style={{ fontWeight: "bold", fontSize: "2.2rem" }}>
                  ${formatDisplayPrice(hoveredPrice)}
                </span>
              </h1>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "0.9rem",
                  margin: "5px 0px",
                  height: "20px",
                }}
              >
                <span
                  className={`${styles.delta} ${
                    gain ? styles.gain : loss ? styles.loss : styles.neutral
                  }`}
                >
                  {gain ? <>&#x25B2;</> : <>&#x25BC;</>}
                  {loading ? " - " : delta}% ({timeFrame})
                </span>
                {hoveredDateTime && (
                  <span style={{ color: "lightgray", marginLeft: "10px" }}>
                    • {hoveredDateTime}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.chartContainer}>
            {loading && priceData.length === 0 ? (
              <div
                className={styles.skeletonChart}
                style={{
                  height: "32vh",
                  marginBottom: "20px",
                  borderRadius: "10px",
                  background: "#303030",
                  position: "relative",
                  overflow: "hidden",
                }}
              />
            ) : (
              <ChartJSFilledLineChart
                priceData={priceList}
                labels={timeList}
                ref={chartRef}
                onHover={(price, index) => {
                  setHoveredPrice(price);
                  setHoveredDelta(calculateDelta(price, priceList[0]));
                  setHoveredDateTime(formatDateTime(priceData[index].unixTime));
                }}
                onMouseLeave={() => {
                  setHoveredPrice(null);
                  setHoveredDelta(null);
                  setHoveredDateTime(null);
                }}
              />
            )}
          </div>
          <div className={styles.timeFrameContainer}>
            <div
              className={`${styles.timeFrameOption} ${
                timeFrame === "1D" ? styles.activeTimeFrame : ""
              }`}
              onClick={() => setTimeFrame("1D")}
            >
              1D
            </div>
            <div
              className={`${styles.timeFrameOption} ${
                timeFrame === "1W" ? styles.activeTimeFrame : ""
              }`}
              onClick={() => setTimeFrame("1W")}
            >
              1W
            </div>
            <div
              className={`${styles.timeFrameOption} ${
                timeFrame === "1M" ? styles.activeTimeFrame : ""
              }`}
              onClick={() => setTimeFrame("1M")}
            >
              1M
            </div>
            <div
              className={`${styles.timeFrameOption} ${
                timeFrame === "1Y" ? styles.activeTimeFrame : ""
              }`}
              onClick={() => setTimeFrame("1Y")}
            >
              1Y
            </div>
            <div
              className={`${styles.timeFrameOption} ${
                timeFrame === "All" ? styles.activeTimeFrame : ""
              }`}
              onClick={() => setTimeFrame("All")}
            >
              All
            </div>
            {/* {loading && priceData.length > 0 && (
              <CircularProgress color="inherit" size={20} />
            )} */}
          </div>
          <div style={{ padding: "0px 24px" }}>
            <div className={styles.actionContainer}>
              <button
                onClick={
                  // () => setMode(BuySellModes.BUY)
                  () => {
                    navigate(
                      `/swap?pdestinationNetwork=${
                        selectedToken.chainIds[0].chainId
                      }&pdestinationAddress=${
                        selectedToken.chainIds[0].address
                      }&psourceNetwork=${
                        selectedToken.chainIds[0].chainId
                      }&psourceAddress=${
                        tokens.USDC[selectedToken.chainIds[0].chainId]
                      }&txntype=BUY`
                    );
                  }
                }
              >
                Buy
              </button>
              {tokenBalance && (
                <button
                  onClick={
                    // () => setMode(BuySellModes.SELL)
                    () => {
                      navigate(
                        `/swap?psourceNetwork=${
                          selectedToken.chainIds[0].chainId
                        }&psourceAddress=${
                          selectedToken.chainIds[0].address
                        }&pdestinationNetwork=${
                          selectedToken.chainIds[0].chainId
                        }&pdestinationAddress=${
                          tokens.USDC[selectedToken.chainIds[0].chainId]
                        }&txntype=SELL`
                      );
                    }
                  }
                >
                  Sell
                </button>
              )}
            </div>

            {tokenBalance && (
              <div className={styles.balanceContainer}>
                <div
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  <h2> Your balance</h2>
                </div>
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                  }}
                >
                  <span>
                    {tokenBalance * selectedToken.price < 0.001
                      ? " <$0.001"
                      : "$" +
                        formatPrice(
                          (tokenBalance * selectedToken.price).toFixed(3)
                        )}
                  </span>
                  <Send
                    onClick={() => {
                      navigate(
                        `/transfer?pdestinationNetwork=${selectedToken.chainIds[0].chainId}&pdestinationAddress=${selectedToken.chainIds[0].address}`
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div style={{ color: "#8A8A8A", fontSize: "14px" }}>
                  {tokenBalance < 0.001 ? "<0.001" : tokenBalance}{" "}
                  {selectedToken.symbol}
                </div>
              </div>
            )}
            {Object.keys(tokenOverview).length > 0 && (
              <div className={styles.statsContainer}>
                {selectedToken.description && (
                  <>
                    {" "}
                    <h2 style={{ marginBottom: "5px" }}>About</h2>
                    <div>
                      <p
                        className={styles.description}
                        style={descriptionStyle}
                      >
                        {selectedToken.description}
                      </p>
                      {selectedToken.description?.length > 150 && (
                        <button
                          onClick={() =>
                            setShowFullDescription(!showFullDescription)
                          }
                          className={styles.readMoreButton}
                        >
                          {showFullDescription ? "Show Less" : "Read More"}
                        </button>
                      )}
                    </div>
                  </>
                )}

                <h2 style={{ marginTop: "20px", marginBottom: "5px" }}>
                  Stats
                </h2>
                <div className={styles.statsCardContainer}>
                  <div className={styles.statsCard}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <span className={styles.icon}>
                        <ChartPie size={18} />
                      </span>
                      <span
                        style={{
                          color: "lightgray",
                          fontSize: "1rem",
                        }}
                      >
                        Market Cap
                      </span>
                    </div>
                    <span style={{ fontSize: "1rem" }}>
                      ${getRep(selectedToken.mc, 1)}
                    </span>
                  </div>
                  <div className={styles.statsCard}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <span className={styles.icon}>
                        <ChartPie size={18} />
                      </span>
                      <span
                        style={{
                          color: "lightgray",
                          fontSize: "1rem",
                        }}
                      >
                        Fully Diluted Valuation
                      </span>
                    </div>
                    <span style={{ fontSize: "1rem" }}>
                      ${getRep(selectedToken.fdv, 1)}
                    </span>
                  </div>
                  <div className={styles.statsCard}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <span className={styles.icon}>
                        <ChartNoAxesColumn size={18} />
                      </span>
                      <span
                        style={{
                          color: "lightgray",
                          fontSize: "1rem",
                        }}
                      >
                        24h Volume
                      </span>
                    </div>
                    <span style={{ fontSize: "1rem" }}>
                      ${getRep(selectedToken.v24hUSD, 1)}
                    </span>
                  </div>
                  <div className={styles.statsCard}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <span className={styles.icon}>
                        <ChartLine size={18} />
                      </span>
                      <span
                        style={{
                          color: "lightgray",
                          fontSize: "1rem",
                        }}
                      >
                        Supply
                      </span>
                    </div>
                    <span style={{ fontSize: "1rem" }}>
                      {getRep(tokenOverview.supply, 1)}
                    </span>
                  </div>
                  <div className={styles.statsCard}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <span className={styles.icon}>
                        <Users size={18} />
                      </span>
                      <span
                        style={{
                          color: "lightgray",
                          fontSize: "1rem",
                        }}
                      >
                        Holders
                      </span>
                    </div>
                    <span style={{ fontSize: "1rem" }}>
                      {getRep(tokenOverview.holder, 1)}
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div style={{ margin: "20px 0px" }}>
              <h2 style={{ marginBottom: "10px" }}>Links</h2>
              <div className={styles.linksContainer}>
                {tokenOverview.extensions?.website && (
                  <a
                    style={{ textDecoration: "none" }}
                    href={tokenOverview.extensions.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className={styles.linkButton}>
                      <Public className={styles.linkButtonIcon} />
                      Website
                    </button>
                  </a>
                )}
                {tokenOverview.extensions?.twitter && (
                  <a
                    style={{ textDecoration: "none" }}
                    href={tokenOverview.extensions.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className={styles.linkButton}>
                      <XIcon className={styles.linkButtonIcon} />
                      Twitter
                    </button>
                  </a>
                )}
              </div>
            </div>
            <div className={styles.networkContainer}>
              <div style={{ color: "lightgray", marginBottom: "10px" }}>
                Supported Chains
              </div>
              <div className={styles.networkLogoContainer}>
                {selectedToken.chainIds.map((chain) => {
                  return (
                    <img src={networkDetails[chain.chainId].image} width={24} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {mode !== BuySellModes.NONE && (
        <BuySellPopup
          mode={mode}
          setMode={setMode}
          selectedToken={selectedToken}
        />
      )}
    </>
  );
};
